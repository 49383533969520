import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Header, Icon } from 'semantic-ui-react'
import { cloneDeep, merge } from 'lodash'
import { compare, Operation } from 'fast-json-patch'
import { toastr } from 'react-redux-toastr'
import { patch, run } from '../../util/remote/wdql'
import { selectConflicts } from '../../selectors'
import ConflictHandlerContent from './ConflictHandlerContent'

// const fakeConflict_1 = {
//   id: '8e657c158a6010c7daea025777d59216',
//   resolved: false,
//   key: 'order',
//   action: 'patch',
//   diff: {
//     '/accounting/approved': {
//       new: false,
//       revert: true,
//     },
//     '/accountingMessage': {
//       new: 'Change the accounting message and disable it now',
//       revert: 'The real accounting message in the DB',
//     },
//   },
//   revert: [
//     {
//       op: 'replace',
//       path: '/accounting/approved',
//       value: true,
//     },
//     {
//       op: 'replace',
//       path: '/accountingMessage',
//       value: 'The real accounting message in the DB',
//     },
//   ],
//   object: {
//     accounting: {
//       approved: true,
//       required: true,
//     },
//     accountingMessage: 'The real accounting message in the DB',
//     createdAt: '2019-03-26 05:07:38',
//     createdBy: 100008,
//     customerId: 100450,
//     description: 'Filler description content 3948d2fcf3af3cdb5abf4011ad0629a1',
//     id: 50805,
//     lastModifiedAt: '2019-04-08 13:26:11',
//     lastModifiedBy: 100043,
//     name: 'ORDER #0dcff3aaeb28b6b4ff765b999bb616b8',
//     status: 'COMPLETE',
//     subStatus: {
//       billing: true,
//       recurring: false,
//       work: true,
//     },
//     type: 'CONTRACT',
//   },
//   patch: {
//     accountingMessage: 'Change the accounting message and disable it now',
//     accounting: {
//       approved:false
//     }
//   },
//   primaryKeys: {
//     id: 50805
//   }
// }

//const fakeConflicts = [fakeConflict_1]

interface RevertObject {
  [key: string]: boolean
}

const ConflictHandler: React.FC = () => {
  const [revert, setRevert] = useState<RevertObject>({})

  const conflicts = useSelector(selectConflicts, shallowEqual)

  const getCurrentConflict = () => {
    return conflicts && conflicts.length > 0 ? conflicts[0] : null
  }

  const resolveConflict = async () => {
    const conflict = getCurrentConflict()
    if (conflict) {
      const patchKey = conflict.key
      const baseObject = cloneDeep(conflict.object)
      const pKeys = conflict.primaryKeys
      const revertKeys = Object.keys(revert).filter((k) => {
        return revert && revert.hasOwnProperty(k) && revert[k]
      })
      const patches = conflict.revert.filter(
        (r) => revertKeys.findIndex((k) => r.path === k) > -1
      )
      const pList = patches as Operation[]
      const mergedObject = merge({}, baseObject, conflict.patch)
      const patchOps = compare(baseObject, mergedObject).filter((o) => {
        return pList.findIndex((i) => i.op === o.op && i.path === o.path) < 0
      })
      const masterPatches = patchOps.concat(pList) as Operation[]
      //const patchedObject = applyPatch(baseObject, masterPatches).newDocument

      const patchData = {
        ...pKeys,
        __conflictPatches: masterPatches,
        __conflictResolutionId: conflict.id,
      }

      //console.log('NEW PATCH CONTENT', patchData)

      await patch(patchKey, pKeys, patchData, { prePatch: false })
      run()

      toastr.success(
        'Conflict Resolved!',
        `The conflict resolution was sent to the server`
      )
    }
  }

  const setKeyToRevert = (key: string, shouldRevert: boolean = true) => {
    const newRevert = { ...revert }
    newRevert[key] = shouldRevert
    setRevert(newRevert)
  }

  const conflict = getCurrentConflict()
  if (conflicts && conflicts.length < 1) {
    return null
  }
  if (!conflict) {
    return null
  }

  //console.log('CONFLICTS:', conflicts)
  //console.log('REVERT:', this.state.revert)
  return (
    <Modal isOpen={true} centered size="lg" keyboard={false} scrollable={true}>
      <ModalHeader style={{ backgroundColor: '#ffdbdb' }}>
        <Header>Database Conflict Detected !</Header>
        <small>
          You have attempted to save information to the database that has
          changed since the last time it was loaded for you. To be sure someone
          else did not make a relevant change that you wish to keep, please
          select which data should be kept{' '}
          <Icon name="check circle" color="green" />
          and which will be overwritten{' '}
          <Icon name="remove circle" color="red" />.
        </small>
      </ModalHeader>
      <ModalBody>
        <ConflictHandlerContent
          conflict={conflict}
          status={revert}
          onChange={setKeyToRevert}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={resolveConflict}>
          Mark As Resolved
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ConflictHandler
