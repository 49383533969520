import { action } from 'typesafe-actions'
import { CampaignEntity } from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_CAMPAIGNS = 'WDQL_MERGE_CAMPAIGNS'
export const WDQL_MERGE_CAMPAIGN = 'WDQL_MERGE_CAMPAIGN'

// Action Definitions
export const wdqlMergeCampaigns = (payload: CampaignEntity[]) =>
  action(WDQL_MERGE_CAMPAIGNS, payload)

export const wdqlMergeCampaign = (payload: CampaignEntity) =>
  action(WDQL_MERGE_CAMPAIGN, payload)
