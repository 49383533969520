import { ActionType } from 'typesafe-actions'
import * as actions from '../actions/state-provinces'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import { StateProvinceEntity } from '../types/entity-types'

const StateProvinceReducer = (
  state: StateProvinceEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_STATE_PROVINCE:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_STATE_PROVINCES:
      return wdqlMergeSet(state, action.payload)
    default:
      return state
  }
}

export default StateProvinceReducer
