import { action } from 'typesafe-actions'
import { UserEntity } from '../types/entity-types'

export interface UserApiMapUpdatePayload {
  userId: number
  code: string
  value: string | null
}

// Action Keys

export const WDQL_MERGE_USERS = 'WDQL_MERGE_USERS'
export const WDQL_MERGE_USER = 'WDQL_MERGE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_API_MAP = 'UPDATE_USER_API_MAP'

export const PATCH_USER_API_MAP = 'PATCH_USER_API_MAP'

// Action Definitions
export const updateUser = (payload: UserEntity) => action(UPDATE_USER, payload)

// WDQL actions
export const wdqlMergeUsers = (payload: UserEntity[]) =>
  action(WDQL_MERGE_USERS, payload)

export const wdqlMergeUser = (payload: UserEntity) =>
  action(WDQL_MERGE_USER, payload)

export const patchUserApiMap = (payload: UserApiMapUpdatePayload) =>
  action(UPDATE_USER_API_MAP, payload)
