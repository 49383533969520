import { merge, cloneDeep } from 'lodash'
import dotProp from 'dot-prop'
import moment from 'moment'
import produce from 'immer'
import stringify from 'json-stable-stringify'
import { AxiosResponse } from 'axios'
import config, { MAX_FETCH_LENGTH, MAX_FETCH_RECORDS } from '../../wdql-redux'
import md5 from '../md5'
import {
  addPatchConflict,
  addWdqlCacheKey,
  resolvePatchConflict,
  updateFlightStatus,
} from '../../actions/wdql'
import { PartialEntityObject } from '../../types/entity-types'

import { WdqlRunOptions, WdqlRunResponse } from '../../actions/types'
import debug from '../debug'
import { getStoreInstance } from '../../store'
import { ReduxState } from '../../types'
import {
  BatchList,
  ConfigBlock,
  ConfigLookup,
  CreateEntityOptionsProp,
  FetchMethodOptionsProp,
  PatchMethodOptionsProp,
  RemoveMethodOptionsProp,
  StitchContainer,
  WdqlLookupId,
  WdqlLookupIdObject,
  WdqlMode,
  WdqlRequest,
} from './types'
import { post } from './xhr'

// Common container to hold the batch list waiting to be processed
const wdqlBatch: BatchList = []

// ********************************************************************
// Public functions for populating state object sets
// ********************************************************************

// SUPPORTING INTERNAL FUNCTIONS (used by the public "run" function)

const executeBatch = async (req: WdqlRequest) => {
  await executePreDispatch(req.type, req, 'runPrepatch', false)

  // @todo Wire in the onRun call here

  if (req.meta && req.meta.onRunDispatch) {
    await executeRunDispatch(req.type, req)
  }
}

const _runCall = async (
  flightKey: string,
  currentBatch: BatchList
): Promise<any[] | boolean> => {
  if (currentBatch.length < 1) {
    return true
  }
  const store = getStoreInstance()
  debug.wdql(
    `[ WDQL RUN ] : ${currentBatch.length} Queries`,
    flightKey,
    currentBatch.map((b) => {
      return { key: b.key, type: b.type }
    })
  )

  if (store) {
    await store.dispatch(updateFlightStatus({ key: flightKey, status: true }))
  }

  const batches = []
  for (let i = 0; i < currentBatch.length; i++) {
    batches.push(executeBatch(currentBatch[i]))
  }
  await Promise.all(batches)

  const response = await Wdql.run(currentBatch)
  let resp: any[] | boolean = false

  if (
    typeof response !== 'boolean' &&
    response.status === 200 &&
    response.data.success &&
    Array.isArray(response.data.results)
  ) {
    resp = response.data.results.filter(
      (x: any) => x.success
      //&& ['create', 'delete', 'patch'].findIndex((t) => x.type === t) > -1
    )
    //resp.results = response.data.results
  }
  // console.log('RESPONSE', response);

  if (store) {
    await store.dispatch(updateFlightStatus({ key: flightKey, status: false }))
  }

  debug.wdql(
    '[ WDQL COMPLETE ] STATE :',
    flightKey,
    store?.getState() ?? 'No Store'
  )

  return resp
}

// Call this AFTER you have called methods to populate the requests you want batched to the WDQL server process
// Examples: fetch, load, create, etc. (All found below)
export const run = (options: WdqlRunOptions | null = null): WdqlRunResponse => {
  const optFlightKey = options?.flightKey ?? null
  const currentBatch: BatchList = scrubDuplicateFetches(cloneDeep(wdqlBatch))
  wdqlBatch.splice(0, wdqlBatch.length)
  const flightKey = optFlightKey ?? generateFlightKey(currentBatch)

  return {
    flightKey: flightKey,
    inFlight: (s: ReduxState | null = null) =>
      checkWdqlFlightStatus(flightKey, s),
    wdqlResponse: _runCall(flightKey, currentBatch),
  }
}

// FETCH an array of objects
// key: the action key (configured in wdql-redux.ts for handling the dispatches when responses come back
// props: a string array of the fields you want to populate in the state for an object. Be sure the ID fields
//        are in this list or it will not be able to merge with existing records (it will stack them)
// filter: an object of properties you want sent over to the WDQL server for things like list filters, lookups, etc
// options: An object with various options that can be passed in to modify behavior
//    batchSize: If specified will grab results in chunck no bigger than this, will keep grabbing til server says done
//              If not specified, will use the wdql-redux.ts configuration constant as the default batch size
//    cache: [default: true] If TRUE will only run exact (key|props|filter) request ONE time for whole application
//           (will always rerun if the page reloads, but if staying on same page it never updates the state again)
//            [number] If you set this to a number (in milliseconds) it will only reload the state results for this
//            if that number of MS have passed since the last fetch of this exact request
//            [false] If false, every call to this fetch request will repost to the server and update the state
//    stitch: [boolean] If this is FALSE (default), as batched requests will update the state AS they come in,
//            So this will apply progressive updates with multiple re-renders if the state values are used
//            When set to true, it collects all batches and combines them together to only update the state one time
//            once all results are back
export const fetch = async (
  key: string,
  props: string[],
  filter: Record<string, any>,
  options: FetchMethodOptionsProp = { cache: true }
): Promise<boolean> => {
  const meta = {
    off: 0,
    max:
      options && options.batchSize && options.batchSize <= MAX_FETCH_LENGTH
        ? options.batchSize
        : MAX_FETCH_LENGTH,
    stitch: options && options.stitch ? options.stitch : false,
  }
  const store = getStoreInstance()
  const requeryKey = getRequeryKey(key, props, filter)
  const loadDataNow = checkForWdqlCache(options.cache, requeryKey)
  if (loadDataNow) {
    await Wdql.add('fetch', {
      key,
      requeryKey,
      type: 'fetch',
      props,
      filter,
      meta,
    })
    if (store) {
      await store.dispatch(
        addWdqlCacheKey({ key: requeryKey, value: moment().valueOf() })
      )
    }
  }
  return true
}

// PATCH a single state entity
// Whatever partial object is sent back from the WDQL request will be dispatched to update the state
// key: the action key (configured in wdql-redux.ts for handling the dispatches when responses come back
// id: number or object. If a number, ".id" property of the object from the entities that the patched data will
//                be applied to. If an object, key:value pairs of primary keys that identify the object
// data: The patch data
// options: An object with various options that can be passed in to modify behavior
//    prePatch (default true): boolean if true, will apply the patched data directly to the state with a dispatch
//                      before sending the WDQL request. If true and the patch WDQL returns a partial object, it will
//                      dispatch the data sent back as well (usually only false if the WDQL call would be mutating
//                      or changing the values server side before they should be applied to the actual state). If
//                      prePatch is true AND the WDQL response includes an object, only the properties that are new
//                      or mutated in the response will be redispatched
//                      if false, the patch will not be made to the existing data and only the response from
//                      the WDQL call will be dispatched
//    prePatchBeforeRun (default false): If prePatch is true and this is true, apply the immediate dispatch as soon
//                      as this method is called. If false (by default), it will not apply the patch until the "run"
//                      method is called as its looping through WDQL calls and triggering their remote posts.
export const patch = async (
  key: string,
  id: WdqlLookupId,
  data: PartialEntityObject,
  options?: PatchMethodOptionsProp | null,
  extraMeta?: Record<string, any>
): Promise<boolean> => {
  const ids: WdqlLookupIdObject = typeof id === 'number' ? { id } : id
  const opts = options ? options : { prePatch: true, prePatchBeforeRun: false }
  const runPrepatch = Boolean(opts.prePatch)
  const runPrepatchOnAdd = runPrepatch && opts.prePatchBeforeRun
  const newData = cloneDeep(data)
  Object.keys(ids).forEach((k) => {
    newData[k] = ids[k]
  })
  const meta = {
    runPrepatch: runPrepatch && !runPrepatchOnAdd,
    runPrepatchOnAdd, // decides if KEY.patch.onAddDispatch is run if its defined in wdql-config
    current: wdqlExistingEntity(key, id),
  }

  await Wdql.add('patch', {
    key,
    type: 'patch',
    data: newData,
    meta: extraMeta ? Object.assign({}, meta, extraMeta) : meta,
  })

  return true
}

// CREATE a one or more state entities
// Whatever object are sent back from the WDQL request will be dispatched to push into the state
// key: the action key (configured in wdql-redux.ts for handling the dispatches when responses come back
// data: The data to create with or an array of data objects to create
// options: An object with various options that can be passed in to modify behavior
//    id (default undefined): number or an object. If its a number, it becomes an object { id: options.id }
//        The id object is passed in the meta data to be used to FORCE the newly created object to use the id or
//        multi-key primary key properties to insert. If an array of data objects are passed, this must be an
//        array of id/objs or it will be ignored, if a single data object is passed and this is an array, the first entry
//        will be used
export const create = async (
  key: string,
  data: PartialEntityObject | PartialEntityObject[],
  options?: CreateEntityOptionsProp | null,
  extraMeta?: Record<string, any>
) => {
  const meta = {
    forceIds:
      options && options.id
        ? typeof options.id === 'number'
          ? { id: options.id }
          : options.id
        : null,
  }
  await Wdql.add('create', {
    key,
    type: 'create',
    data,
    meta: extraMeta ? Object.assign({}, meta, extraMeta) : meta,
  })
}

export const load = async (key: string, id: number, props: string[]) => {
  const meta = {}
  const filter = { id: id }
  await Wdql.add('fetch', {
    key,
    requeryKey: getRequeryKey(key, props, filter),
    type: 'fetch',
    props,
    filter,
    meta,
  })
}

// DELETE/REMOVE one or more state entities
// The primary key or array of keys is sent to the server to process deletion
// key: the action key (configured in wdql-redux.ts for handling the dispatches when responses come back
// id: number or object. If a number, ".id" property of the object from the entities that the patched data will
//                be applied to. If an object, key:value pairs of primary keys that identify the object. This can also
//                be an array of id number/objects to be deleted
// options: An object with various options that can be passed in to modify behavior
//    onRunDispatch (default true): boolean if true, will remove the entities from the redux state before firing
//                      the API call but after the RUN has been called on the batch
//                      if false, the deletion will not be made to the entity list until the response sends back ids
//                      to be removed
export const remove = async (
  key: string,
  id: WdqlLookupId | WdqlLookupId[],
  options: RemoveMethodOptionsProp | null = null,
  extraMeta?: Record<string, any>
): Promise<boolean> => {
  const opts = options ? options : { preDelete: false }
  const idList: WdqlLookupIdObject[] | null = Array.isArray(id)
    ? id.map((i: WdqlLookupId) => (typeof i === 'number' ? { id: i } : i))
    : typeof id === 'number'
    ? [{ id }]
    : [id]

  const onRunDispatch = Boolean(opts.onRunDispatch)
  const data = { id: idList }
  const meta = {
    onRunDispatch: onRunDispatch, // decides if KEY.patch.onRunDelete is run if its defined in wdql-config
  }

  await Wdql.add('delete', {
    key,
    type: 'delete',
    data,
    meta: extraMeta ? Object.assign({}, meta, extraMeta) : meta,
  })

  return true
}

// ********************************************************************
// Scrub Duplicate Fetches
// ********************************************************************

export const scrubDuplicateFetches = (batches: BatchList): BatchList => {
  const rKeys: { [k: string]: true } = {}
  const b: BatchList = batches.reduce((all: BatchList, batch) => {
    if (batch.type !== 'fetch') {
      all.push(batch)
    } else if (!rKeys[batch.requeryKey]) {
      rKeys[batch.requeryKey] = true
      all.push(batch)
    }
    return all
  }, [])

  return b
}

// ********************************************************************
// Check inFlight status of a Key
// ********************************************************************

export const checkWdqlFlightStatus = (
  flightKey: string,
  state: ReduxState | null = null
): boolean => {
  let useState = state
  if (useState === null) {
    const store = getStoreInstance()
    useState = store?.getState() ?? null
  }
  if (useState === null) {
    return false
  }
  return useState.wdql.flights.findIndex((f) => f.key === flightKey) > -1
}

const generateFlightKey = (b: BatchList): string => {
  return md5('BATCH:' + stringify(b))
}

// ********************************************************************
// Lookup
// ********************************************************************
export const wdqlExistingEntity = (
  key: string,
  id: WdqlLookupId
): { [key: string]: any } | null => {
  const store = getStoreInstance()
  if (store) {
    const lookup = getWdqlLookup(key)
    const objectKey = !!lookup ? dotProp.get(lookup, 'entity') || key : key
    const ids: WdqlLookupIdObject = typeof id === 'number' ? { id } : id
    const lookupKeys = !!lookup ? dotProp.get(lookup, 'keys') : null
    const idKeys: string[] =
      lookupKeys && Array.isArray(lookupKeys)
        ? lookupKeys
        : lookupKeys && typeof lookupKeys === 'string'
        ? lookupKeys.split(',').map((k) => String(k).trim())
        : ['id']
    const state: ReduxState = store.getState()
    const data = dotProp.get(state, `entities.${objectKey}`)
    if (data && Array.isArray(data)) {
      const m = data.findIndex((f: any) => {
        return (
          idKeys.length > 0 &&
          idKeys.reduce((a, k) => {
            return (
              a &&
              ids.hasOwnProperty(k) &&
              f.hasOwnProperty(k) &&
              ids[k] === f[k]
            )
          }, true)
        )
      })
      if (m > -1) {
        return cloneDeep(data[m])
      }
    }
  }
  return null
}

// ***********************************************************************************
// Utility Function to wait for sync run call and successful responses to all entries
// ***********************************************************************************

export const wdqlSuccess = async (wdqlCall: Promise<WdqlRunResponse>) => {
  const apiResponse = await (await wdqlCall).wdqlResponse
  if (apiResponse && Array.isArray(apiResponse) && apiResponse.length > 0) {
    return apiResponse.reduce((a, entry: { success: boolean }) => {
      return a && entry.success
    }, true)
  }
  return false
}

// ********************************************************************
// Action Handler Callbacks
// ********************************************************************

export const wdqlMergeSet = <T, S>(
  existing: T[],
  patch: S[],
  matchOn: string | string[] = 'id',
  replace: string[] = []
): T[] => {
  //return existing
  const mKeys = Array.isArray(matchOn) ? matchOn : [matchOn]
  return produce(existing, (newSet: T[]) => {
    patch.map((n: any) => {
      const m = newSet.findIndex((f: any) => {
        return (
          mKeys.length > 0 &&
          mKeys.reduce((a, k) => {
            return (
              a && n.hasOwnProperty(k) && f.hasOwnProperty(k) && n[k] === f[k]
            )
          }, true)
        )
      })
      if (m > -1) {
        const tmp = merge({}, newSet[m])
        replace.forEach((path) => {
          if (dotProp.get(n, path)) {
            dotProp.delete(tmp, path)
          }
        })
        newSet[m] = merge({}, tmp, n)
      } else {
        if (
          n &&
          mKeys.reduce((a, k) => {
            return a && n.hasOwnProperty(k)
          }, true)
        ) {
          newSet.push(n)
        }
      }
      return null
    })
  })
}

export const wdqlMergeOne = <T, S>(
  existing: T[],
  patch: S,
  matchOn: string | string[] = 'id',
  replace: string[] = []
): T[] => {
  const mKeys = Array.isArray(matchOn) ? matchOn : [matchOn]
  return produce(existing, (newSet: T[]) => {
    const p: any = patch
    const m = newSet.findIndex((f: any) => {
      return (
        mKeys.length > 0 &&
        mKeys.reduce((a, k) => {
          return (
            a && p.hasOwnProperty(k) && f.hasOwnProperty(k) && p[k] === f[k]
          )
        }, true)
      )
    })
    if (m > -1) {
      const tmp = merge({}, newSet[m])
      replace.forEach((path) => {
        if (dotProp.get(p, path)) {
          dotProp.delete(tmp, path)
        }
      })
      newSet[m] = merge({}, tmp, p)
    } else {
      if (
        p &&
        mKeys.reduce((a, k) => {
          return a && p.hasOwnProperty(k)
        }, true)
      ) {
        newSet.push(p)
      }
    }
  })
}

// ********************************************************************
// Supporting Functions and Classes - Private to Module (no exports)
// ********************************************************************

const getWdqlLookup = (key: string): ConfigLookup | null => {
  const c = dotProp.get(config, `${key}.lookup`) as ConfigLookup
  return c || null
}

const getWdqlConfig = (key: string, method: string): ConfigBlock | null => {
  const c = dotProp.get(config, `${key}.${method}`) as ConfigBlock
  return c || null
}

const executePreDispatch = async (
  type: WdqlMode,
  req: WdqlRequest,
  metaKey: 'runPrepatch' | 'runPrepatchOnAdd',
  defaultRun: boolean = true
) => {
  const c = getWdqlConfig(req.key, type)
  const runPreDispatchSetting = dotProp.get(req, `meta.${metaKey}`)
  const runPreDispatch =
    runPreDispatchSetting === true || runPreDispatchSetting === false
      ? runPreDispatchSetting
      : defaultRun
  const preDispatch =
    c && c.onAddDispatch && runPreDispatch ? c.onAddDispatch : null
  if (preDispatch) {
    const store = getStoreInstance()
    if (store && req.data) {
      await store.dispatch(preDispatch(req.data))
    }
  }
}

const executeRunDispatch = async (type: WdqlMode, req: WdqlRequest) => {
  const c = getWdqlConfig(req.key, type)
  const onRunDispatch = c && c.onRunDispatch ? c.onRunDispatch : null
  if (onRunDispatch) {
    const store = getStoreInstance()
    if (store && req.data) {
      await store.dispatch(onRunDispatch(req.data))
    }
  }
}

class Wdql {
  static batch: BatchList = []

  public static run = async (
    b: BatchList,
    stitches: StitchContainer[] = []
  ): Promise<AxiosResponse | boolean> => {
    if (b.length < 1) {
      return true
    }
    const response = await post('/api/wdql/001/batch', b)
    await parse(response, b, stitches)
    return response
  }

  public static add = async (type: WdqlMode, req: WdqlRequest) => {
    //const b = Wdql.batch
    const b = wdqlBatch
    const c = getWdqlConfig(req.key, type)
    const pushReq: WdqlRequest = c && c.onAdd ? c.onAdd(req) : req
    await executePreDispatch(type, pushReq, 'runPrepatchOnAdd', true)
    if (b) {
      b.push(pushReq)
    }
  }
}

const checkForWdqlCache = (
  cacheMode: boolean | number | undefined,
  requeryKey: string
) => {
  const useCache: boolean | number =
    typeof cacheMode === 'undefined' ? true : cacheMode
  let foundCache = false
  if (useCache) {
    const store = getStoreInstance()
    if (store) {
      const state: ReduxState = store.getState()
      const cacheValue: number =
        dotProp.get(state, 'wdql.cache.' + requeryKey) || 0
      foundCache = useCache === true && !!cacheValue
      foundCache =
        typeof useCache === 'number'
          ? moment().valueOf() < cacheValue + useCache
          : foundCache
    }
  }
  return !useCache || (useCache && !foundCache)
}

const getRequeryKey = (
  key: string,
  props: string[],
  filter: Record<string, any>
): string => {
  return key + md5(key + stringify([...props].sort()) + stringify(filter))
}

const parse = async (
  resp: any,
  req: BatchList,
  stitches: StitchContainer[]
) => {
  debug.wdql('[ WDQL REQUEST ]', req, stitches)
  debug.wdql('[ WDQL RESPONSE ]', resp)
  const requeries: BatchList = []
  const data = resp.data
  const store = getStoreInstance()
  if (!data.success) {
    debug.error(
      '[ WDQL ERROR ]',
      ' Response : There was at least one failed request returned'
    )
  }
  const results = data.results
  if (store && results && Array.isArray(results)) {
    for (const obj of results) {
      if (obj.success) {
        const requeryKey =
          obj.meta && obj.meta.requeryKey ? obj.meta.requeryKey : null
        const useStitch = obj.meta && obj.meta.stitch && requeryKey

        let data = obj.response
        if (useStitch) {
          const stitchBlockId = stitches.findIndex((v) => v.key === requeryKey)
          const stitchBlock =
            stitchBlockId > -1
              ? stitches[stitchBlockId]
              : { key: requeryKey, data: [] }
          stitchBlock.data = stitchBlock.data.concat(data)
          if (stitchBlockId < 0) {
            stitches.push(stitchBlock)
          }
          data = stitchBlock.data
        }
        obj.response = data

        let stitching = false
        if (obj.meta && obj.meta.requeryKey && obj.meta.off) {
          if (
            obj.response &&
            Array.isArray(obj.response) &&
            obj.response.length > 0
          ) {
            const requery = req.find((o: any) => {
              return o.requeryKey && o.requeryKey === obj.meta.requeryKey
            })
            const r = runRequery(requery, obj.meta)
            if (r) {
              stitching = true
              requeries.push(r)
            }
          }
        }
        if (
          obj.type === 'patch' &&
          obj.response &&
          obj.response.hasOwnProperty('__conflict')
        ) {
          const conflictData = cloneDeep(obj.response.__conflict)
          await store.dispatch(addPatchConflict(conflictData))
          let forcedResponse = {}
          if (obj.response.hasOwnProperty('__data')) {
            forcedResponse = cloneDeep(obj.response.__data)
          }
          obj.response = forcedResponse
        }

        if (!useStitch || !stitching) {
          debug.wdql('[ WDQL PROCESS ]', `${obj.type}.${obj.key}`, obj)
          //let doMeta = false
          const c = getWdqlConfig(obj.key, obj.type)
          const onResponseMutate =
            c && c.onResponseMutate ? c.onResponseMutate : null
          const mutatedObj = onResponseMutate ? onResponseMutate(obj) : obj
          const action = c && c.onResponseDispatch ? c.onResponseDispatch : null
          if (action) {
            await store.dispatch(action(mutatedObj.response))
            //doMeta = true
          }
          const followup = c && c.onResponseClose ? c.onResponseClose : null
          if (followup) {
            await followup(mutatedObj)
            //doMeta = true
          }
          if (obj.meta && obj.meta.__conflictResolutionId) {
            await store.dispatch(
              resolvePatchConflict(String(obj.meta.__conflictResolutionId))
            )
          }
        }
      } else {
        debug.wdql('[ WDQL ERRORS ]', obj.errors)
      }
    }
  }
  if (requeries.length > 0) {
    debug.wdql('[ WDQL RUN REQUERIES ]', requeries, stitches)
    await Wdql.run(requeries, stitches)
  }
  return true
}

const runRequery = (requery: any, meta: any) => {
  const newQuery = cloneDeep(requery)
  debug.wdql('[ WDQL FOUND REQUERY ]', requery, meta)
  const max = MAX_FETCH_RECORDS
  if (requery && requery.meta) {
    let rMax: number = (meta && meta.max) || MAX_FETCH_LENGTH
    const rOff: number = (meta && meta.off) || 0
    //const rCount:number = meta && meta.count || 1
    if (rMax + rOff > max) {
      rMax = rMax - Math.abs(max - (rMax + rOff))
    }
    if (rOff < max && rMax > 0) {
      newQuery.meta.off = rOff
      newQuery.meta.max = rMax
      //console.log('PUSH REQUERY:', newQuery);
      //return null
      return newQuery
    }
  }
  return null
}
