import { action } from 'typesafe-actions'
import { StarEntity } from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_STARS = 'WDQL_MERGE_STARS'
export const WDQL_MERGE_STAR = 'WDQL_MERGE_STAR'
export const WDQL_REMOVE_STAR = 'WDQL_REMOVE_STAR'
export const WDQL_REMOVE_STARS = 'WDQL_REMOVE_STARS'

// Action Definitions
// WDQL actions
export const wdqlMergeStars = (payload: StarEntity[]) =>
  action(WDQL_MERGE_STARS, payload)
export const wdqlMergeStar = (payload: StarEntity) =>
  action(WDQL_MERGE_STAR, payload)
export const removeStar = (payload: number | number[]) =>
  action(WDQL_REMOVE_STAR, payload)
export const removeStars = (payload: number | number[]) =>
  action(WDQL_REMOVE_STARS, payload)
