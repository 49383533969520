import { action } from 'typesafe-actions'
import {
  CLEAR_USER_PROFILE,
  UPDATE_USER_PROFILE,
} from '../constants/action-types'
import { UserProfile } from './types'

export const updateUserProfile = (payload: UserProfile) =>
  action(UPDATE_USER_PROFILE, payload)
export const clearUserProfile = () => action(CLEAR_USER_PROFILE)
