import { action } from 'typesafe-actions'
import { StateProvinceEntity } from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_STATE_PROVINCES = 'WDQL_MERGE_STATE_PROVINCES'
export const WDQL_MERGE_STATE_PROVINCE = 'WDQL_MERGE_STATE_PROVINCE'

// Action Definitions
// WDQL actions
export const wdqlMergeStateProvinces = (payload: StateProvinceEntity[]) =>
  action(WDQL_MERGE_STATE_PROVINCES, payload)
export const wdqlMergeStateProvince = (payload: StateProvinceEntity) =>
  action(WDQL_MERGE_STATE_PROVINCE, payload)
