import debug from './debug'

debug.init() // Placeholder to include debug while unused

// interface RedirectorObject {
//   current: () => void
//   store: (p: string) => void
//   fetch: (d?: string | null) => string | null
//   clear: () => void
// }

const redirector = {
  current: () => {
    const currentPath = window.location.pathname
      ? String(window.location.pathname)
      : null
    if (currentPath) {
      if (currentPath !== '/login' && currentPath !== '/activate') {
        redirector.store(currentPath)
      }
    } else {
      redirector.clear()
    }
  },

  store: (path: string | null) => {
    if (path) {
      localStorage.setItem('loginRedirect', path)
    } else {
      redirector.clear()
    }
  },

  fetch: (def?: string | null) => {
    const defaultPath = def === null ? null : def || '/'
    const stored = localStorage.getItem('loginRedirect') || null
    return stored || defaultPath
  },

  clear: () => {
    localStorage.removeItem('loginRedirect')
  },
}

export default redirector
