import { merge } from 'lodash'
import { ActionType } from 'typesafe-actions'
import {
  CLEAR_USER_PROFILE,
  UPDATE_USER_PROFILE,
} from '../constants/action-types'
import * as actions from '../actions/userProfile'

const UserProfileReducer = (state = {}, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case UPDATE_USER_PROFILE:
      return merge({}, state, action.payload)
    case CLEAR_USER_PROFILE:
      return {}
    default:
      return state
  }
}

export default UserProfileReducer
