import { ActionType } from 'typesafe-actions'
import * as actions from '../actions/campaigns'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import { CampaignEntity } from '../types/entity-types'

const CampaignReducer = (
  state: CampaignEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_CAMPAIGN:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_CAMPAIGNS:
      return wdqlMergeSet(state, action.payload)
    default:
      return state
  }
}

export default CampaignReducer
