import { action } from 'typesafe-actions'
import { SalesGroupEntity } from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_SALES_GROUPS = 'WDQL_MERGE_SALES_GROUPS'
export const WDQL_MERGE_SALES_GROUP = 'WDQL_MERGE_SALES_GROUP'

// Action Definitions
// WDQL actions
export const wdqlMergeSalesGroups = (payload: SalesGroupEntity[]) =>
  action(WDQL_MERGE_SALES_GROUPS, payload)
export const wdqlMergeSalesGroup = (payload: SalesGroupEntity) =>
  action(WDQL_MERGE_SALES_GROUP, payload)
