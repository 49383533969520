import { action } from 'typesafe-actions'
import { ActivityFeedEntity } from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_ACTIVITY_FEED = 'WDQL_MERGE_ACTIVITY_FEED'
export const WDQL_MERGE_ACTIVITY_ITEM = 'WDQL_MERGE_ACTIVITY_ITEM'

// Action Definitions
export const wdqlMergeActivityFeed = (payload: ActivityFeedEntity[]) =>
  action(WDQL_MERGE_ACTIVITY_FEED, payload)

export const wdqlMergeActivityItem = (payload: ActivityFeedEntity) =>
  action(WDQL_MERGE_ACTIVITY_ITEM, payload)
