import { ActionType } from 'typesafe-actions'
import * as actions from '../actions/project-platforms'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import { PlatformEntity, ProjectEntity } from '../types/entity-types'

export const ProjectReducer = (
  state: ProjectEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_PROJECT:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_PROJECTS:
      return wdqlMergeSet(state, action.payload)
    default:
      return state
  }
}

export const PlatformReducer = (
  state: PlatformEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_PLATFORM:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_PLATFORMS:
      return wdqlMergeSet(state, action.payload)
    default:
      return state
  }
}

//
// const makeProjectPlatformReducer = (k: string) => {
//   if (k === 'projects') {
//     return (state:ProjectEntity[] = [], action: ActionType<typeof actions>) => {
//       switch (action.type) {
//         case actions.WDQL_MERGE_PROJECT:
//           return wdqlMergeOne(state, action.payload)
//         case actions.WDQL_MERGE_PROJECTS:
//           return wdqlMergeSet(state, action.payload)
//         default:
//           return state
//       }
//     }
//   }
//   // Return platforms reducer function
//   return (state:PlatformEntity[] = [], action: ActionType<typeof actions>) => {
//     switch (action.type) {
//       case actions.WDQL_MERGE_PLATFORM:
//         return wdqlMergeOne(state, action.payload)
//       case actions.WDQL_MERGE_PLATFORMS:
//         return wdqlMergeSet(state, action.payload)
//       default:
//         return state
//     }
//   }
// }
//
// export default makeProjectPlatformReducer
