import { ConfigStruct } from './util/remote/types'

// Import WDQL Actions
import {
  wdqlRemoveOrderDraft,
  removeOrderItems,
  wdqlMergeOrder,
  wdqlMergeOrderDraft,
  wdqlMergeOrderDrafts,
  wdqlMergeOrderItem,
  wdqlMergeOrderItems,
  wdqlMergeOrders,
} from './actions/orders'
import { wdqlMergeCustomers, wdqlMergeCustomer } from './actions/customers'
import { wdqlMergeUser, wdqlMergeUsers } from './actions/users'
import {
  wdqlMergeSalesGroup,
  wdqlMergeSalesGroups,
} from './actions/sales-groups'
import { wdqlMergeDomain, wdqlMergeDomains } from './actions/domains'
import { wdqlMergeAddress, wdqlMergeAddresses } from './actions/addresses'
import {
  wdqlMergeStateProvince,
  wdqlMergeStateProvinces,
} from './actions/state-provinces'
import {
  wdqlMergePlatform,
  wdqlMergePlatforms,
  wdqlMergeProject,
  wdqlMergeProjects,
} from './actions/project-platforms'
import {
  wdqlMergeMpbCategories,
  wdqlMergeMpbCategory,
  wdqlMergeMpbItem,
  wdqlMergeMpbItems,
} from './actions/master-price-book'
import {
  removeUploadedFile,
  removeUploadedFiles,
  wdqlMergeUploadedFile,
  wdqlMergeUploadedFiles,
} from './actions/uploaded-files'
import { wdqlMergeRole, wdqlMergeRoles } from './actions/roles'
import {
  wdqlMergeActivityFeed,
  wdqlMergeActivityItem,
} from './actions/activityFeed'
import { wdqlMergeCampaigns, wdqlMergeCampaign } from './actions/campaigns'
import {
  removeStar,
  removeStars,
  wdqlMergeStar,
  wdqlMergeStars,
} from './actions/stars'
import {
  wdqlMergeCreditLedgerBucket,
  wdqlMergeCreditLedgerBuckets,
} from './actions/credit-ledger-buckets'

// Move to a CONFIG location (for now set manually)
export const MAX_FETCH_LENGTH = 2500
export const MAX_FETCH_RECORDS = 100000

// Map all WDQL fetch calls to their proper dispatch actions on response
const config: ConfigStruct = {
  user: {
    lookup: {
      entity: 'users',
    },
    fetch: {
      onResponseDispatch: wdqlMergeUser,
    },
  },
  users: {
    fetch: {
      onResponseDispatch: wdqlMergeUsers,
    },
  },
  role: {
    lookup: {
      entity: 'roles',
    },
    fetch: {
      onResponseDispatch: wdqlMergeRole,
    },
    patch: {
      onAddDispatch: wdqlMergeRole,
      onResponseDispatch: wdqlMergeRole,
    },
  },
  roles: {
    fetch: {
      onResponseDispatch: wdqlMergeRoles,
    },
  },
  order: {
    lookup: {
      entity: 'orders',
    },
    fetch: {
      onResponseDispatch: wdqlMergeOrder,
    },
    patch: {
      onAddDispatch: wdqlMergeOrder,
      onResponseDispatch: wdqlMergeOrder,
    },
    create: {
      onResponseDispatch: wdqlMergeOrder,
    },
  },
  orders: {
    fetch: {
      onResponseDispatch: wdqlMergeOrders,
    },
  },
  orderItem: {
    lookup: {
      entity: 'orderItems',
    },
    fetch: {
      onResponseDispatch: wdqlMergeOrderItem,
    },
    patch: {
      onAddDispatch: wdqlMergeOrderItem,
      onResponseDispatch: wdqlMergeOrderItem,
    },
    create: {
      onResponseDispatch: wdqlMergeOrderItem,
    },
  },
  orderItems: {
    fetch: {
      onResponseDispatch: wdqlMergeOrderItems,
    },
    create: {
      onResponseDispatch: wdqlMergeOrderItems,
    },
    delete: {
      onRunDispatch: (d: any) => removeOrderItems(d.id),
      onResponseDispatch: removeOrderItems,
    },
  },
  customer: {
    lookup: {
      entity: 'customers',
    },
    fetch: {
      onResponseDispatch: wdqlMergeCustomer,
    },
    patch: {
      onAddDispatch: wdqlMergeCustomer,
      onResponseDispatch: wdqlMergeCustomer,
    },
  },
  customers: {
    fetch: {
      onResponseDispatch: wdqlMergeCustomers,
    },
  },
  salesGroup: {
    lookup: {
      entity: 'salesGroup',
    },
    fetch: {
      onResponseDispatch: wdqlMergeSalesGroup,
    },
  },
  salesGroups: {
    fetch: {
      onResponseDispatch: wdqlMergeSalesGroups,
    },
  },
  domain: {
    lookup: {
      entity: 'domains',
    },
    fetch: {
      onResponseDispatch: wdqlMergeDomain,
    },
  },
  domains: {
    fetch: {
      onResponseDispatch: wdqlMergeDomains,
    },
  },
  address: {
    lookup: {
      entity: 'addresses',
    },
    fetch: {
      onResponseDispatch: wdqlMergeAddress,
    },
  },
  addresses: {
    fetch: {
      onResponseDispatch: wdqlMergeAddresses,
    },
  },
  stateProvince: {
    lookup: {
      entity: 'stateProvinces',
    },
    fetch: {
      onResponseDispatch: wdqlMergeStateProvince,
    },
  },
  stateProvinces: {
    fetch: {
      onResponseDispatch: wdqlMergeStateProvinces,
    },
  },
  platform: {
    lookup: {
      entity: 'platforms',
    },
    fetch: {
      onResponseDispatch: wdqlMergePlatform,
    },
  },
  platforms: {
    fetch: {
      onResponseDispatch: wdqlMergePlatforms,
    },
  },
  project: {
    lookup: {
      entity: 'projects',
    },
    fetch: {
      onResponseDispatch: wdqlMergeProject,
    },
  },
  projects: {
    fetch: {
      onResponseDispatch: wdqlMergeProjects,
    },
  },
  mpbCategory: {
    lookup: {
      entity: 'masterPriceBook.categories',
    },
    fetch: {
      onResponseDispatch: wdqlMergeMpbCategory,
    },
  },
  mpbCategories: {
    fetch: {
      onResponseDispatch: wdqlMergeMpbCategories,
    },
  },
  mpbItem: {
    lookup: {
      entity: 'masterPriceBook.items',
    },
    fetch: {
      onResponseDispatch: wdqlMergeMpbItem,
    },
    create: {
      onResponseDispatch: wdqlMergeMpbItem,
    },
    patch: {
      onAddDispatch: wdqlMergeMpbItem,
      onResponseDispatch: wdqlMergeMpbItem,
    },
  },
  mpbItems: {
    fetch: {
      onResponseDispatch: wdqlMergeMpbItems,
    },
  },
  uploadedFile: {
    lookup: {
      entity: 'uploadedFiles',
    },
    fetch: {
      onResponseDispatch: wdqlMergeUploadedFile,
    },
    create: {
      onResponseDispatch: wdqlMergeUploadedFile,
    },
    delete: {
      onRunDispatch: (d: any) => removeUploadedFile(d.id),
      onResponseDispatch: removeUploadedFile,
    },
  },
  uploadedFiles: {
    fetch: {
      onResponseDispatch: wdqlMergeUploadedFiles,
    },
    create: {
      onResponseDispatch: wdqlMergeUploadedFiles,
    },
    delete: {
      onRunDispatch: (d: any) => removeUploadedFiles(d.id),
      onResponseDispatch: removeUploadedFiles,
    },
  },
  orderDraft: {
    lookup: {
      entity: 'orderDrafts',
    },
    fetch: {
      onResponseDispatch: wdqlMergeOrderDraft,
    },
    create: {
      onResponseDispatch: wdqlMergeOrderDraft,
    },
    patch: {
      onAddDispatch: wdqlMergeOrderDraft,
      onResponseDispatch: wdqlMergeOrderDraft,
    },
    delete: {
      onResponseDispatch: wdqlRemoveOrderDraft,
    },
  },
  orderDrafts: {
    fetch: {
      onResponseDispatch: wdqlMergeOrderDrafts,
    },
  },
  activityItem: {
    lookup: {
      entity: 'activityFeed',
    },
    fetch: {
      onResponseDispatch: wdqlMergeActivityItem,
    },
  },
  activityFeed: {
    fetch: {
      onResponseDispatch: wdqlMergeActivityFeed,
    },
  },
  campaign: {
    lookup: {
      entity: 'campaigns',
    },
    fetch: {
      onResponseDispatch: wdqlMergeCampaign,
    },
    patch: {
      onAddDispatch: wdqlMergeCampaign,
      onResponseDispatch: wdqlMergeCampaign,
    },
  },
  campaigns: {
    fetch: {
      onResponseDispatch: wdqlMergeCampaigns,
    },
  },
  star: {
    lookup: {
      entity: 'stars',
    },
    fetch: {
      onResponseDispatch: wdqlMergeStar,
    },
    create: {
      onResponseDispatch: wdqlMergeStar,
    },
    delete: {
      onRunDispatch: (d: any) => removeStar(d.id),
      onResponseDispatch: removeStar,
    },
  },
  stars: {
    fetch: {
      onResponseDispatch: wdqlMergeStars,
    },
    delete: {
      onRunDispatch: (d: any) => removeStars(d.id),
      onResponseDispatch: removeStars,
    },
  },
  creditLedgerBucket: {
    lookup: {
      entity: 'creditLedgerBuckets',
    },
    fetch: {
      onResponseDispatch: wdqlMergeCreditLedgerBucket,
    },
  },
  creditLedgerBuckets: {
    fetch: {
      onResponseDispatch: wdqlMergeCreditLedgerBuckets,
    },
  },
  // create: {
  //   order: {
  //     onAddDispatch: createOrder,
  //     onResponseDispatch: patchOrder
  //   }
  // }
}

export default config
