const wdqlLoggingEnabled = process.env.REACT_APP_DEBUG_WDQL_OUTPUT ?? false
// console.log('env.NODE_ENV', process.env.NODE_ENV)
// console.log(
//   'env.REACT_APP_DEBUG_WDQL_OUTPUT',
//   process.env.REACT_APP_DEBUG_WDQL_OUTPUT
// )

const debug = {
  init: () => null,
  log: (...a: any[]) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(...a)
    }
  },
  update: (...a: any[]) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(...a)
    }
  },
  live: (...a: any[]) => {
    console.log(...a)
  },
  error: (...a: any[]) => {
    console.error(...a)
  },
  warn: (...a: any[]) => {
    console.warn(...a)
  },
  tron: (d: any, label?: string, preview?: any | null) => {
    if (process.env.NODE_ENV === 'development') {
      const lab = label ?? 'debug.tron'
      tron(d, lab, preview)
    }
  },
  wdql: (...a: any[]) => {
    if (wdqlLoggingEnabled) {
      debug.update(...a)
    }
  },
}

export default debug
