import { ActionType } from 'typesafe-actions'
import {
  CLEAR_USER_ACCESS,
  UPDATE_USER_ACCESS,
} from '../constants/action-types'
import * as actions from '../actions/access'

const AccessReducer = (state = {}, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case UPDATE_USER_ACCESS:
      return action.payload
    case CLEAR_USER_ACCESS:
      return {}
    default:
      return state
  }
}

export default AccessReducer
