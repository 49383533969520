import React, { useEffect } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable'
import './App.scss'
import history from './history'
import { loadRoles } from './loaders'
import GlobalStateProvider from './store/state/GlobalStateProvider'

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
)

// Containers
const PageLayout = Loadable({
  loader: () => import('./layout/PageLayout'),
  loading,
})

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading,
})

const Activate = Loadable({
  loader: () => import('./views/Pages/Activate'),
  loading,
})

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading,
})

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading,
})

const App: React.FC = () => {
  useEffect(() => {
    loadRoles()
  }, [])

  return (
    <GlobalStateProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/activate" component={Activate} />
          <Route exact path="/404" component={Page404} />
          <Route exact path="/500" component={Page500} />
          <Route path="/" component={PageLayout} />
        </Switch>
      </Router>
    </GlobalStateProvider>
  )
}

export default App
