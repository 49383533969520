import { ActionType } from 'typesafe-actions'
import * as actions from '../actions/master-price-book'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import {
  MasterPriceBookCategoryEntity,
  MasterPriceBookItemEntity,
} from '../types/entity-types'

export interface MasterPriceBookEntityContainer {
  categories: MasterPriceBookCategoryEntity[]
  items: MasterPriceBookItemEntity[]
}

export const MasterPriceBookCategoryReducer = (
  state: MasterPriceBookCategoryEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_MPB_CATEGORY:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_MPB_CATEGORIES:
      return wdqlMergeSet(state, action.payload)
    default:
      return state
  }
}

export const MasterPriceBookItemReducer = (
  state: MasterPriceBookItemEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_MPB_ITEM:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_MPB_ITEMS:
      return wdqlMergeSet(state, action.payload)
    default:
      return state
  }
}
