import { action } from 'typesafe-actions'
import { ProjectEntity, PlatformEntity } from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_PROJECTS = 'WDQL_MERGE_PROJECTS'
export const WDQL_MERGE_PROJECT = 'WDQL_MERGE_PROJECT'
export const WDQL_MERGE_PLATFORMS = 'WDQL_MERGE_PLATFORMS'
export const WDQL_MERGE_PLATFORM = 'WDQL_MERGE_PLATFORM'

// Action Definitions
// WDQL actions
export const wdqlMergeProjects = (payload: ProjectEntity[]) =>
  action(WDQL_MERGE_PROJECTS, payload)
export const wdqlMergeProject = (payload: ProjectEntity) =>
  action(WDQL_MERGE_PROJECT, payload)
export const wdqlMergePlatforms = (payload: PlatformEntity[]) =>
  action(WDQL_MERGE_PLATFORMS, payload)
export const wdqlMergePlatform = (payload: PlatformEntity) =>
  action(WDQL_MERGE_PLATFORM, payload)
