import { action } from 'typesafe-actions'
import { DomainEntity } from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_DOMAINS = 'WDQL_MERGE_DOMAINS'
export const WDQL_MERGE_DOMAIN = 'WDQL_MERGE_DOMAIN'

// Action Definitions
// WDQL actions
export const wdqlMergeDomains = (payload: DomainEntity[]) =>
  action(WDQL_MERGE_DOMAINS, payload)
export const wdqlMergeDomain = (payload: DomainEntity) =>
  action(WDQL_MERGE_DOMAIN, payload)
