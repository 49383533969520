//import { merge } from 'lodash'
import produce from 'immer'
import { ActionType } from 'typesafe-actions'
import { ADD_PD_CONVERSATIONS } from '../constants/action-types'
import * as actions from '../actions/helpscout'
import { HelpScoutConversationType } from '../store/lookup/helpscout'
import { ReduxState } from '../types'

const HelpscoutReducer = (
  state: ReduxState['helpscout'] = { conversations: [] },
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case ADD_PD_CONVERSATIONS:
      return produce(state, (newState) => {
        //console.log('ADD NEW HELPSCOUT CONVERSATIONS', action.payload)
        const { conversations } = newState
        action.payload.forEach((newConvo) => {
          const index = conversations.findIndex(
            (c: HelpScoutConversationType) => c.id === newConvo.id
          )
          if (index > -1) {
            conversations[index] = newConvo
          } else {
            conversations.push(newConvo)
          }
        })
        newState.conversations = conversations
        return newState
      })
    default:
      return state
  }
}

export default HelpscoutReducer
