import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Header, Icon, Segment } from 'semantic-ui-react'
import { WdqlPatchConflict } from '../../actions/types'
import getConflictHandler from './handlers'

interface ConflictRowData {
  key: string
  label: string
  db: string
  mine: string
  maxLength?: number
}

interface Props {
  conflict: WdqlPatchConflict
  status: { [key: string]: boolean }
  onChange: (k: string, v: boolean) => void
}

const ConflictHandlerContent: React.FC<Props> = (props: Props) => {
  const { status, conflict } = props

  const getRevert = (key: string): boolean => {
    if (status && status.hasOwnProperty(key)) {
      return Boolean(status[key])
    }
    return false
  }

  if (!conflict) {
    return null
  }
  const handler = getConflictHandler(conflict.key)
  const keyData = Object.keys(conflict.diff).map((k) => {
    const obj = conflict.diff[k]
    return {
      key: k,
      label: handler.label(k),
      db: handler.value(k, obj.revert),
      mine: handler.value(k, obj.new),
    }
  })
  const conflictCaption = handler.header(conflict)

  return (
    <Container>
      <Row className="show-grid">
        <Col xs={12}>
          <Segment raised>{conflictCaption}</Segment>
        </Col>
      </Row>
      {keyData.map((d) => (
        <ConflictRow
          key={d.key}
          data={d}
          willRevert={getRevert(d.key)}
          onChange={(v: boolean) => props.onChange(d.key, v)}
        />
      ))}
    </Container>
  )
}

export default ConflictHandlerContent

interface ConflictRowProps {
  data: ConflictRowData
  onChange: (v: boolean) => void
  willRevert: boolean
}

const ConflictRow: React.FC<ConflictRowProps> = (props: ConflictRowProps) => {
  return (
    <div>
      <Row className="show-grid">
        <Col
          xs={12}
          style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
        >
          <Header>{props.data.label}</Header>
        </Col>
      </Row>
      <Row className="show-grid" style={{ marginBottom: 15 }}>
        <Col xs={12} lg={6}>
          <ConflictBox
            onChange={() => props.onChange(true)}
            type="db"
            selected={props.willRevert}
            text={props.data.db}
            maxLength={props.data.maxLength || undefined}
          />
        </Col>
        <Col xs={12} lg={6}>
          <ConflictBox
            onChange={() => props.onChange(false)}
            type="mine"
            selected={!props.willRevert}
            text={props.data.mine}
            maxLength={props.data.maxLength || undefined}
          />
        </Col>
      </Row>
    </div>
  )
}

interface ConflictBoxProps {
  type: 'db' | 'mine'
  selected: boolean
  text: string
  maxLength?: number
  onChange: () => void
}

const MAX_CONFLICT_TEXT_LENGTH = 250

const ConflictBox: React.FC<ConflictBoxProps> = (props: ConflictBoxProps) => {
  const { type, selected, text } = props
  const color = selected ? 'green' : 'red'
  const icon = selected ? 'check circle' : 'remove circle'
  const caption =
    type === 'db'
      ? selected
        ? 'Keep the database value'
        : 'Replace the database value'
      : selected
      ? 'Keep my change'
      : 'Discard my change'
  const strikeStyle = selected ? undefined : { textDecoration: 'line-through' }
  const mLength =
    props.maxLength && props.maxLength > 0
      ? props.maxLength
      : MAX_CONFLICT_TEXT_LENGTH
  const showText =
    text && text.length > mLength ? text.substr(0, mLength) + '...' : text

  return (
    <Segment
      raised
      color={color}
      onClick={props.onChange}
      style={{ cursor: 'pointer' }}
    >
      <Header size="medium">
        <Icon name={icon} color={color} /> {caption}
      </Header>
      <hr />
      <span style={strikeStyle}>{showText}</span>
    </Segment>
  )
}
