import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useReducer,
} from 'react'
import {
  globalReducer,
  GlobalStateActions,
  GlobalStateInterface,
  initialGlobalState,
} from './global-state-reducer'

export interface GlobalContextInterface {
  state: GlobalStateInterface
  dispatch: Dispatch<GlobalStateActions>
}

export const GlobalContext = createContext<GlobalContextInterface>({
  state: initialGlobalState,
  dispatch: (_: GlobalStateActions) => null,
})

interface GlobalStateProviderProps {
  children: ReactNode
}

const GlobalStateProvider: FC<GlobalStateProviderProps> = (
  props: GlobalStateProviderProps
) => {
  const [state, dispatch] = useReducer(globalReducer, initialGlobalState)
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  )
}

export default GlobalStateProvider
