import { action } from 'typesafe-actions'
import {
  OrderDraftEntity,
  OrderEntity,
  OrderItemEntity,
} from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_ORDERS = 'WDQL_MERGE_ORDERS'
export const WDQL_MERGE_ORDER = 'WDQL_MERGE_ORDER'

export const WDQL_MERGE_ORDER_ITEMS = 'WDQL_MERGE_ORDER_ITEMS'
export const WDQL_MERGE_ORDER_ITEM = 'WDQL_MERGE_ORDER_ITEM'
export const REMOVE_ORDER_ITEMS = 'REMOVE_ORDER_ITEMS'

export const WDQL_MERGE_ORDER_DRAFTS = 'WDQL_MERGE_ORDER_DRAFTS'
export const WDQL_MERGE_ORDER_DRAFT = 'WDQL_MERGE_ORDER_DRAFT'
export const WDQL_REMOVE_ORDER_DRAFT = 'WDQL_REMOVE_ORDER_DRAFT'

// Action Definitions
// WDQL actions
export const wdqlMergeOrders = (payload: OrderEntity[]) =>
  action(WDQL_MERGE_ORDERS, payload)
export const wdqlMergeOrder = (payload: OrderEntity) =>
  action(WDQL_MERGE_ORDER, payload)

// Order Items
export const wdqlMergeOrderItems = (payload: OrderItemEntity[]) =>
  action(WDQL_MERGE_ORDER_ITEMS, payload)
export const wdqlMergeOrderItem = (payload: OrderItemEntity) =>
  action(WDQL_MERGE_ORDER_ITEM, payload)
export const removeOrderItems = (payload: number | number[]) =>
  action(REMOVE_ORDER_ITEMS, payload)

// WDQL actions
export const wdqlMergeOrderDrafts = (payload: OrderDraftEntity[]) =>
  action(WDQL_MERGE_ORDER_DRAFTS, payload)
export const wdqlMergeOrderDraft = (payload: OrderDraftEntity) =>
  action(WDQL_MERGE_ORDER_DRAFT, payload)
export const wdqlRemoveOrderDraft = (payload: number) =>
  action(WDQL_REMOVE_ORDER_DRAFT, payload)
