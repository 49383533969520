import { run, fetch, patch, create } from '../util/remote/wdql'
import { WdqlRunResponse } from '../actions/types'
import { get } from '../util/remote/xhr'

import {
  PipelineDealsContact,
  PipelineDealsDeal,
} from '../store/lookup/pipeline-response-types'
import { addPipelineContacts } from '../actions/pipelineDeals'
import { HelpScoutConversationType } from '../store/lookup/helpscout'
import { addHelpscoutConversations } from '../actions/helpscout'
import { MasterPriceBookItemEntity, StarEntity } from '../types/entity-types'
import { validAuth } from '../util/authenticate'
import { getStoreInstance } from '../store'
import { PaymoCompanySimple } from '../types/api'

// Customers
export const loadCustomers = async (): Promise<WdqlRunResponse> => {
  await fetch('customers', ['*'], { cache: 60000, batchSize: 2000 })
  return run()
}

export const loadCustomer = async (id: number) => {
  //console.log('Load Customer', id)
  await fetch('customer', ['*'], { id: id }, { cache: false })
  return run()
}

// Orders
export const loadOrders = async (): Promise<WdqlRunResponse> => {
  await fetch('customers', ['id', 'name', 'status'], {
    cache: 60000,
    batchSize: 2000,
  })
  await fetch('orders', ['*'], {}, { cache: 5000 })
  return run()
}

export const loadOrder = async (id: number): Promise<WdqlRunResponse> => {
  await fetch('order', ['*'], { id: id }, { cache: false })
  await fetch('orderItems', ['*'], { orderId: id }, { cache: false })
  return run()
}

export const loadOrderItems = async (
  orderId: number
): Promise<WdqlRunResponse> => {
  await fetch('orderItems', ['*'], { orderId: orderId }, { cache: false })
  return run()
}

// Sales Groups

export const loadSalesGroups = async (): Promise<WdqlRunResponse> => {
  await fetch('salesGroups', ['*'], {})
  return run()
}

export const loadSalesGroup = async (id: number): Promise<WdqlRunResponse> => {
  await fetch('salesGroup', ['*'], { id: id }, {})
  return run()
}

// Users

export const loadUsers = async (
  limitToIds: number[] | null = null
): Promise<WdqlRunResponse> => {
  const limitIds = limitToIds ? { only: limitToIds } : {}
  await fetch('users', ['*'], { ...limitIds }, { cache: 60000 })
  return run()
}

export const loadUser = async (
  userId: number,
  force: boolean = false
): Promise<WdqlRunResponse> => {
  await fetch('user', ['*'], { id: userId }, { cache: !force ? 10000 : 0 })
  return run()
}

// Roles

export const loadRoles = async (
  limitToIds: number[] | null = null
): Promise<WdqlRunResponse> => {
  const limitIds = limitToIds ? { only: limitToIds } : {}
  await fetch('roles', ['*'], { ...limitIds }, { cache: 60000 })
  return run()
}

export const loadRole = async (roleId: number): Promise<WdqlRunResponse> => {
  await fetch('role', ['*'], { id: roleId }, { cache: 10000 })
  return run()
}

// Users & Roles Combo

export const loadUsersRoles = async (
  limitToUserIds: number[] | null = null,
  limitToRoleIds: number[] | null = null
): Promise<WdqlRunResponse> => {
  const limitUserIds = limitToUserIds ? { only: limitToUserIds } : {}
  await fetch('users', ['*'], { ...limitUserIds }, { cache: 60000 })
  const limitRoleIds = limitToRoleIds ? { only: limitToRoleIds } : {}
  await fetch('roles', ['*'], { ...limitRoleIds }, { cache: 60000 })
  return run()
}

// Domains

export const loadDomains = async (): Promise<WdqlRunResponse> => {
  await fetch('domains', ['*'], {})
  return run()
}

// Projects

export const loadProjects = async (
  customerId: number
): Promise<WdqlRunResponse> => {
  await fetch('projects', ['*'], { customerId })
  return run()
}

// Platforms

export const loadPlatforms = async (
  customerId: number
): Promise<WdqlRunResponse> => {
  await fetch('platforms', ['*'], { customerId })
  return run()
}

export const loadProjectsAndPlatforms = async (
  customerId: number
): Promise<WdqlRunResponse> => {
  await fetch('projects', ['*'], { customerId })
  await fetch('platforms', ['*'], { customerId })
  const flightKey = `loadProjectsAndPlatforms_${customerId}`
  return run({ flightKey })
}

export const loadMasterPriceBook = async (): Promise<WdqlRunResponse> => {
  await fetch('mpbCategories', ['*'], {})
  await fetch('mpbItems', ['*'], {})
  return run({ flightKey: `loadMasterPriceBook` })
}

export const saveMasterPriceBookItem = async (
  item: MasterPriceBookItemEntity
): Promise<WdqlRunResponse> => {
  if (item.id > 0) {
    await patch('mpbItem', item.id, item)
  } else {
    await create('mpbItem', item)
  }
  return run()
}

export const fetchPdDealsByCompanyApiId = async (
  pdCompanyId: number
): Promise<PipelineDealsDeal[] | null> => {
  const response = await get(
    `/api/pipeline-deals/001/get-deals?companyId=${pdCompanyId}`
  )
  if (validAuth(response)) {
    const d: PipelineDealsDeal[] | null | undefined = response.data
    if (d) {
      return d
    }
  }
  return null
}

export const fetchPdDealById = async (
  pdId: number
): Promise<PipelineDealsDeal | null> => {
  const response = await get(`/api/pipeline-deals/001/get-deal?id=${pdId}`)
  if (validAuth(response)) {
    const d: PipelineDealsDeal | null | undefined = response.data
    if (d) {
      return d
    }
  }
  return null
}

export const updatePipelineDeal = async (
  pdId: number,
  type: 'amount' | 'gp',
  amount: number
): Promise<PipelineDealsDeal | null> => {
  const response = await get(
    `/api/pipeline-deals/001/set-deal-value?id=${pdId}&type=${type}&amount=${amount}`
  )
  if (validAuth(response)) {
    const d: PipelineDealsDeal | null | undefined = response.data
    if (d) {
      return d
    }
  }
  return null
}

export const loadUploadedFiles = async (
  limitToIds: ReadonlyArray<number> | null = null
): Promise<WdqlRunResponse> => {
  const limitIds = limitToIds ? { id: limitToIds } : {}
  await fetch('uploadedFiles', ['*'], { ...limitIds }, { cache: 3000 })
  return run()
}

// export const fetchPipelineContacts = _.debounce((pdCompanyId:number)=>{
//   console.log('****FETCH CONTACTS NOW !!!!', pdCompanyId)
// }, 500)

const fetchPipelineContactsMap = new Map()

export const fetchPipelineContacts = async (pdCompanyId: number | number[]) => {
  const ids = (Array.isArray(pdCompanyId) ? pdCompanyId : [pdCompanyId]).join(
    '|'
  )
  const mapKey = ids
  if (!fetchPipelineContactsMap.get(mapKey)) {
    fetchPipelineContactsMap.set(mapKey, true)
    //console.log('****FETCH CONTACTS NOW !!!!', ids)
    const response = await get(
      `/api/pipeline-deals/001/get-company-contacts?companyId=${ids}`
    )
    if (validAuth(response)) {
      const contacts: PipelineDealsContact[] | null | undefined = response.data
      if (contacts) {
        const store = getStoreInstance()
        if (store) {
          store.dispatch(addPipelineContacts(contacts))
        }
      }
    }
    fetchPipelineContactsMap.delete(mapKey)
  }
}

export const fetchPipelineContact = async (pdContactId: number | number[]) => {
  const ids = (Array.isArray(pdContactId) ? pdContactId : [pdContactId]).join(
    '|'
  )
  const mapKey = ids
  if (!fetchPipelineContactsMap.get(mapKey)) {
    fetchPipelineContactsMap.set(mapKey, true)
    //console.log('****FETCH CONTACTS NOW !!!!', ids)
    const response = await get(
      `/api/pipeline-deals/001/get-contacts?contactId=${ids}`
    )
    if (validAuth(response)) {
      const contacts: PipelineDealsContact[] | null | undefined = response.data
      if (contacts) {
        const store = getStoreInstance()
        if (store) {
          store.dispatch(addPipelineContacts(contacts))
        }
      }
    }
    fetchPipelineContactsMap.delete(mapKey)
  }
}

// OrderDrafts

export const loadOrderDrafts = async (
  userId: number | null = null
): Promise<WdqlRunResponse> => {
  await fetch('customers', ['id', 'name', 'status'], {
    cache: 60000,
    batchSize: 2000,
  })
  await fetch('orderDrafts', ['*'], {
    userId: userId,
  })
  return run()
}

export const loadOrderDraft = async (id: number): Promise<WdqlRunResponse> => {
  await fetch('orderDraft', ['*'], { id: id }, { cache: false })
  return run()
}

// Activity Feeds

export const loadActivityFeed = async (
  filter: any
): Promise<WdqlRunResponse> => {
  const cache = { cache: 10000 } // { cache: 5000, batchSize: 2500 }
  await fetch('activityFeed', ['*'], filter, cache)
  return run()
}

export const loadActivityItem = async (id: number) => {
  await fetch('activityItem', ['*'], { id: id }, { cache: false })
  return run()
}

export const fetchHelpScoutRecentSupport = async (): Promise<
  HelpScoutConversationType[] | null
> => {
  const response = await get(
    `/api/helpscout/001/fetch-recent-support-conversations`
  )
  if (validAuth(response)) {
    const convos: HelpScoutConversationType[] | null | undefined = response.data
    if (convos) {
      const store = getStoreInstance()
      if (store) {
        store.dispatch(addHelpscoutConversations(convos))
      }
      return convos
    }
  }
  return null
}

export const fetchPaymoCustomersSimple = async (): Promise<
  PaymoCompanySimple[] | null
> => {
  const response = await get(`/api/paymo/001/fetch-customers-simple`)
  if (validAuth(response)) {
    const companies: PaymoCompanySimple[] | null | undefined = response.data
    if (companies) {
      return companies
    }
    return []
  }
  return null
}

// Customers
export const loadCampaigns = async (): Promise<WdqlRunResponse> => {
  await fetch('campaigns', ['*'], {}, { cache: 60000 })
  return run()
}

export const loadCampaign = async (id: number) => {
  await fetch('campaign', ['*'], { id: id }, { cache: false })
  return run()
}

// Stars

export const loadStars = async (
  userId: number | null,
  type: StarEntity['type']
): Promise<WdqlRunResponse> => {
  await fetch('stars', ['*'], { userId, type, cache: 60000 })
  return run()
}

// Credit Ledger Buckets

export const loadCreditLedgerBuckets = async (): Promise<WdqlRunResponse> => {
  await fetch('creditLedgerBucket', ['*'], {}, { cache: 60000 })
  return run()
}

export const loadCreditLedgerBucket = async (id: number) => {
  await fetch('creditLedgerBucket', ['*'], { id: id }, { cache: false })
  return run()
}

export const loadCreditLedgerBucketsByCustomerId = async (
  customerId: number
): Promise<WdqlRunResponse> => {
  await fetch('creditLedgerBuckets', ['*'], { customerId }, { cache: 60000 })
  return run()
}

//
// export function fetchUser(id) {
//   return (dispatch, getState) => {
//     if (getUserById(getState(), id)) {
//       console.warn('!! we already have the user in state')
//     }
//     dispatch(requestUser(id))
//     return api.getUser(id).then(user => dispatch(receiveUser(user)))
//   }
// }
