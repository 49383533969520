// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface String {
    ucWords(): string
    ucFirst(): string
    nl2br(): string
  }
  interface Number {
    money(withSign?: boolean, withCents?: boolean): string
    moneyFromCents(withSign?: boolean, withCents?: boolean): string
    humanBytes(base10?: boolean): string
    humanHours(): string
    floor(divisor?: number): number
  }
}

// eslint-disable-next-line
String.prototype.ucWords = function (): string {
  return this.replace(/\w+/g, function (a) {
    return a.charAt(0).toUpperCase() + a.slice(1).toLowerCase()
  })
}

// eslint-disable-next-line
String.prototype.ucFirst = function (): string {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase()
}

// eslint-disable-next-line
String.prototype.nl2br = function () {
  const breakTag = '<br />'
  return this.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

// eslint-disable-next-line
Number.prototype.money = function (
  withSign: boolean = true,
  withCents: boolean = true
): string {
  const useCents = withCents && String(this).indexOf('.') > -1
  const isNegative = this < 0
  return (
    (isNegative ? '-' : '') +
    (withSign ? '$' : '') +
    String(Math.abs(Number(this)).toFixed(useCents ? 2 : 0)).replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )
  )
}

// eslint-disable-next-line
Number.prototype.moneyFromCents = function (
  withSign: boolean = true,
  withCents: boolean = true
): string {
  return (Number(this) / 100).money(withSign, withCents)
}

// eslint-disable-next-line
Number.prototype.humanBytes = function (base10: boolean = true) {
  let bytes = Number(this)
  const thresh = base10 ? 1000 : 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }
  const units = base10
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return bytes.toFixed(1) + ' ' + units[u]
}

// eslint-disable-next-line
Number.prototype.humanHours = function () {
  const totalMin = Number(this) * 60
  const hrs = Math.floor(totalMin / 60)
  const min = totalMin - hrs * 60
  const hrsS = hrs === 1 ? '' : 's'
  let str = ''
  if (hrs > 0) {
    str += `${hrs} hr${hrsS}`
  }
  if (min > 0) {
    str += hrs > 0 ? ' ' : ''
    str += `${min} m`
  }
  if (totalMin === 0) {
    return '0 m'
  }
  return str
}

// eslint-disable-next-line
Number.prototype.floor = function (divisor = 1): number {
  return Math.floor(Number(this) / divisor) * divisor
}

export {}
