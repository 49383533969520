import { action } from 'typesafe-actions'
import {
  UPDATE_USER_ACCESS,
  CLEAR_USER_ACCESS,
} from '../constants/action-types'
import { AccessState } from './types'

export const updateAccessData = (payload: AccessState) =>
  action(UPDATE_USER_ACCESS, payload)
export const clearAccessData = () => action(CLEAR_USER_ACCESS)

// export function getData() {
//     return function(dispatch) {
//         return fetch("https://jsonplaceholder.typicode.com/posts")
//             .then(response => response.json())
//             .then(json => {
//                 dispatch({ type: "DATA_LOADED", payload: json });
//             });
//     };
// }
