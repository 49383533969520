import { ActionType } from 'typesafe-actions'
import {
  CLEAR_SESSION_AUTH,
  UPDATE_SESSION_AUTH,
} from '../constants/action-types'
import * as actions from '../actions/session'

const SessionReducer = (state = {}, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case UPDATE_SESSION_AUTH:
      return action.payload
    case CLEAR_SESSION_AUTH:
      return {}
    default:
      return state
  }
}

export default SessionReducer
