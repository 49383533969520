import { action } from 'typesafe-actions'
import { AddressEntity } from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_ADDRESSES = 'WDQL_MERGE_ADDRESSES'
export const WDQL_MERGE_ADDRESS = 'WDQL_MERGE_ADDRESS'

// Action Definitions
// WDQL actions
export const wdqlMergeAddresses = (payload: AddressEntity[]) =>
  action(WDQL_MERGE_ADDRESSES, payload)
export const wdqlMergeAddress = (payload: AddressEntity) =>
  action(WDQL_MERGE_ADDRESS, payload)
