import { Store, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer, { initialState } from '../reducers'
import Reactotron from '../reactotron-config'

//const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
})

const store: Store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(Reactotron.createEnhancer())
)

let storeInstance: Store | null = null

export function setStoreInstance(store: Store) {
  storeInstance = store
}

export function getStoreInstance(): Store | null {
  return storeInstance
}

setStoreInstance(store)

export default store
