import { ActionType } from 'typesafe-actions'
import produce from 'immer'
import * as actions from '../actions/uploaded-files'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import { UploadedFileEntity } from '../types/entity-types'

const UploadedFilesReducer = (
  state: UploadedFileEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_UPLOADED_FILE:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_UPLOADED_FILES:
      return wdqlMergeSet(state, action.payload)
    case actions.MERGE_UPLOADED_FILE:
      return wdqlMergeOne(state, action.payload)
    case actions.MERGE_UPLOADED_FILES:
      return wdqlMergeSet(state, action.payload)
    case actions.REMOVE_UPLOADED_FILE:
    case actions.REMOVE_UPLOADED_FILES:
      return produce(state, (files) => {
        const ids: number[] = (
          Array.isArray(action.payload) ? action.payload : [action.payload]
        ).map((i: number | { id: number }) => {
          if (typeof i === 'number') {
            return i
          }
          return i.id
        })
        const newFiles = files.filter(
          (f: UploadedFileEntity) => ids.findIndex((i) => f.id === i) < 0
        )
        //debugger
        //console.log('NEW FILES', newFiles)
        return newFiles
      })
    default:
      return state
  }
}

export default UploadedFilesReducer
