import Reactotron from 'reactotron-react-js'
import { reactotronRedux } from 'reactotron-redux'

const reactotron = Reactotron.configure({ name: '360 Control Center' })
  .use(reactotronRedux())
  .connect()

export default reactotron

window.tron = (
  value: any,
  name: string | undefined = 'DEBUG',
  preview: any | null | undefined = null
) => {
  if (!preview && value) {
    preview =
      typeof value === 'string'
        ? value
        : Array.isArray(value)
        ? String(value)
        : typeof value
  }
  Reactotron.display({
    name,
    preview,
    value,
  })
}
