import { action } from 'typesafe-actions'
import { ADD_PD_DEAL, ADD_PD_CONTACTS } from '../constants/action-types'
import {
  PipelineDealsContact,
  PipelineDealsDeal,
} from '../store/lookup/pipeline-response-types'

export const addPipelineDeal = (payload: PipelineDealsDeal) =>
  action(ADD_PD_DEAL, payload)

export const addPipelineContacts = (payload: PipelineDealsContact[]) =>
  action(ADD_PD_CONTACTS, payload)
