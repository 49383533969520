import { combineReducers } from 'redux'
import { cloneDeep } from 'lodash'
//import { DeepReadonly } from 'utility-types'
import { reducer as toastrReducer } from 'react-redux-toastr'
import updateAuthState, { AuthStateResponse } from '../util/auth'
import { post } from '../util/remote/xhr'
//import sampleAccessTree from "../_dev/fake-access-tree"

//import { loadUsers } from "../actions/users"
import debug from '../util/debug'
import redirector from '../util/redirector'
import { onLoginScreen } from '../util/authenticate'
import { ReduxState } from '../types'
import WdqlReducer from './wdql'
import AccessReducer from './access'
import InterfaceReducer from './interface'
import CustomersReducer from './customers'
import OrdersReducer, { OrderDraftReducer, OrderItemsReducer } from './orders'
import UserProfileReducer from './userProfile'
import SessionReducer from './session'
import UserReducer from './users'
import SalesGroupReducer from './sales-groups'
import DomainReducer from './domains'
import StarReducer from './stars'
import AddressReducer from './addresses'
import StateProvinceReducer from './state-provinces'
import { PlatformReducer, ProjectReducer } from './project-platforms'
import {
  MasterPriceBookCategoryReducer,
  MasterPriceBookItemReducer,
} from './master-price-book'
import PipelineDealsReducer from './pipelineDeals'
import HelpscoutReducer from './helpscout'
import UploadedFilesReducer from './uploaded-files'
import RoleReducer from './roles'
import ActivityFeedReducer from './activityFeed'
import CampaignReducer from './campaigns'
import CreditLedgerBucketReducer from './credit-ledger-buckets'

const defaultState: ReduxState = {
  userProfile: {},
  session: {},
  access: {},
  interface: {
    favorites: [],
  },
  wdql: {
    cache: {},
    conflicts: [],
    flights: [],
  },
  entities: {
    customers: [],
  },
  pipelineDeals: {
    deals: [],
    contacts: [],
  },
  helpscout: {
    conversations: [],
  },
}

export const initialState: ReduxState = populateInitialState(defaultState)
debug.tron(initialState, 'DEFAULT STATE', JSON.stringify(initialState))
//console.log('default state', initialState)

// Send a reauth check to the server if its not already on the login or register pages
if (!onLoginScreen()) {
  const sessData = initialState.session
  const data = {
    authToken: sessData.authToken,
    [sessData.name as string]: sessData.id,
    ghostId: sessData.ghostId,
  }
  //console.log('Fire Reauth', data)
  redirector.current()
  post('/api/auth/001/reauth', data).then((response) => {
    const data: AuthStateResponse = response.data
    debug.log('REAUTH Response', response.data)
    updateAuthState(data)
  })
}

export default combineReducers({
  userProfile: UserProfileReducer,
  session: SessionReducer,
  access: AccessReducer,
  interface: InterfaceReducer,
  toastr: toastrReducer,
  wdql: WdqlReducer,
  entities: combineReducers({
    orders: OrdersReducer,
    orderDrafts: OrderDraftReducer,
    orderItems: OrderItemsReducer,
    customers: CustomersReducer,
    users: UserReducer,
    roles: RoleReducer,
    salesGroups: SalesGroupReducer,
    domains: DomainReducer,
    addresses: AddressReducer,
    stateProvinces: StateProvinceReducer,
    projects: ProjectReducer,
    platforms: PlatformReducer,
    masterPriceBook: combineReducers({
      categories: MasterPriceBookCategoryReducer,
      items: MasterPriceBookItemReducer,
    }),
    uploadedFiles: UploadedFilesReducer,
    activityFeed: ActivityFeedReducer,
    campaigns: CampaignReducer,
    stars: StarReducer,
    creditLedgerBuckets: CreditLedgerBucketReducer,
  }),
  pipelineDeals: PipelineDealsReducer,
  helpscout: HelpscoutReducer,
})

// Support utility functions

function populateInitialState(defaultState: ReduxState): ReduxState {
  const state: ReduxState = cloneDeep(defaultState)
  const tokenId = localStorage.getItem('tokenId') || null
  if (!tokenId) {
    //console.log('NO TOKEN ID')
    return state
  }
  const sessionName = localStorage.getItem('sessionName') || 'PHPSESSID' // was sessionStorage
  const sessionId = localStorage.getItem('sessionId') || null // was sessionStorage
  const ghostId = localStorage.getItem('ghostId') || null
  state.session = {
    id: sessionId,
    name: sessionName,
    authToken: tokenId,
    ghostId: ghostId,
  }
  //state.entities.orders = []
  //state.entities.uploadedFiles = tmpUploadedFiles  //@REMOVE
  return state
}
