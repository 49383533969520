//import { merge } from 'lodash'
import produce from 'immer'
import { ActionType } from 'typesafe-actions'
import { ADD_PD_CONTACTS, ADD_PD_DEAL } from '../constants/action-types'
import * as actions from '../actions/pipelineDeals'
import {
  PipelineDealsContact,
  PipelineDealsDeal,
} from '../store/lookup/pipeline-response-types'
import { ReduxState } from '../types'

const PipelineDealsReducer = (
  state: ReduxState['pipelineDeals'] = { deals: [], contacts: [] },
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case ADD_PD_DEAL:
      return produce(state, (newState) => {
        const { deals } = newState
        const index = deals.findIndex(
          (d: PipelineDealsDeal) => d.id === action.payload.id
        )
        if (index > -1) {
          deals[index] = action.payload
        } else {
          deals.push(action.payload)
        }
        newState.deals = deals
        return newState
      })
    case ADD_PD_CONTACTS:
      return produce(state, (newState) => {
        //console.log('ADD NEW PD CONTACTS', action.payload)
        const { contacts } = newState
        action.payload.forEach((newContact) => {
          const index = contacts.findIndex(
            (c: PipelineDealsContact) => c.id === newContact.id
          )
          if (index > -1) {
            contacts[index] = newContact
          } else {
            contacts.push(newContact)
          }
        })
        newState.contacts = contacts
        return newState
      })
    default:
      return state
  }
}

export default PipelineDealsReducer
