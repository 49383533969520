import { createSelector } from 'reselect'
import {
  AccessState,
  FavoriteEntry,
  UserProfileDisplay,
  WdqlFlightStatus,
  WdqlPatchConflict,
} from '../actions/types'
import {
  ActivityFeedEntity,
  CampaignEntity,
  CreditLedgerBucketEntity,
  CustomerEntity,
  DomainEntity,
  EditOrderEntity,
  MasterPriceBookItemEntity,
  OrderDraftEntity,
  OrderEntity,
  OrderItemEntity,
  PlatformEntity,
  ProjectEntity,
  RoleEntity,
  SalesGroupEntity,
  StarEntity,
  UploadedFileEntity,
  UserEntity,
} from '../types/entity-types'
import { MasterPriceBookEntityContainer } from '../reducers/master-price-book'
import {
  PipelineDealsContact,
  PipelineDealsDeal,
} from '../store/lookup/pipeline-response-types'
import { HelpScoutConversationType } from '../store/lookup/helpscout'
import { ReduxState } from '../types'
import { getEmptyMasterPriceBookItem } from '../entities/EmptyMasterPriceBookItem'

export const createParameterSelector = (selector: any) => {
  return (_: ReduxState, params: any) => selector(params)
}

export const selectSalesGroups = createSelector(
  (s: ReduxState) => s.entities.salesGroups,
  (salesgroups): SalesGroupEntity[] => salesgroups || []
)

export const selectCustomers = createSelector(
  (s: ReduxState) => s.entities.customers,
  (customers): CustomerEntity[] => customers || []
)

export const selectPipelineDeals = createSelector(
  (s: ReduxState) => s.pipelineDeals.deals,
  (pipelineDeals): PipelineDealsDeal[] => pipelineDeals || []
)

export const selectPipelineDealsPeople = createSelector(
  (s: ReduxState) => s.pipelineDeals.contacts,
  (contacts): PipelineDealsContact[] => contacts || []
)

export const selectMasterPricebook = createSelector(
  (s: ReduxState) => s.entities.masterPriceBook,
  (pricebook): MasterPriceBookEntityContainer =>
    pricebook || { items: [], categories: [] }
)

export const selectMasterPricebookCategories = createSelector(
  (s: ReduxState) => s.entities.masterPriceBook,
  (pricebook): MasterPriceBookEntityContainer['categories'] =>
    pricebook?.categories || []
)

export const selectMasterPricebookItems = createSelector(
  (s: ReduxState) => s.entities.masterPriceBook,
  (pricebook): MasterPriceBookEntityContainer['items'] => pricebook?.items || []
)

export const selectFavorites = createSelector(
  (s: ReduxState) => s.interface.favorites,
  (favorites): FavoriteEntry[] => favorites || []
)

export const selectUsers = createSelector(
  (s: ReduxState) => s.entities.users,
  (users): UserEntity[] => users || []
)

export const selectConflicts = createSelector(
  (s: ReduxState) => s.wdql.conflicts,
  (conflicts): WdqlPatchConflict[] => conflicts || []
)

export const selectHelpscoutConversations = createSelector(
  (s: ReduxState) => s.helpscout.conversations,
  (convos): HelpScoutConversationType[] => convos || []
)

export const selectPlatforms = createSelector(
  (s: ReduxState) => s.entities.platforms,
  (platforms): PlatformEntity[] => platforms || []
)

export const selectProjects = createSelector(
  (s: ReduxState) => s.entities.projects,
  (projects): ProjectEntity[] => projects || []
)

export const selectWdqlFlights = createSelector(
  (s: ReduxState) => s.wdql.flights,
  (flights): WdqlFlightStatus[] => flights || []
)

export const selectUploadedFiles = createSelector(
  (s: ReduxState) => s.entities.uploadedFiles,
  (files): UploadedFileEntity[] => files || []
)

export const selectRoles = createSelector(
  (s: ReduxState) => s.entities.roles,
  (roles): RoleEntity[] => roles || []
)

export const selectCampaigns = createSelector(
  (s: ReduxState) => s.entities.campaigns,
  (campaigns): CampaignEntity[] => campaigns || []
)

export const selectStars = createSelector(
  (s: ReduxState) => s.entities.stars,
  (stars): StarEntity[] => stars || []
)

export const selectActivityFeed = createSelector(
  (s: ReduxState) => s.entities.activityFeed,
  (feed): ActivityFeedEntity[] => feed || []
)

export const selectOrders = createSelector(
  (s: ReduxState) => s.entities.orders,
  (orders): OrderEntity[] => orders || []
)

export const selectOrderItems = createSelector(
  (s: ReduxState) => s.entities.orderItems,
  (items): OrderItemEntity[] => items || []
)

export const selectActiveOrderDrafts = createSelector(
  (s: ReduxState) => s.entities.orderDrafts,
  (drafts): OrderDraftEntity[] =>
    (drafts || []).filter((d) => d.status === 'ACTIVE')
)

export const selectDomains = createSelector(
  (s: ReduxState) => s.entities.domains,
  (domains): DomainEntity[] => domains || []
)

export const selectUserDisplay = (s: ReduxState): UserProfileDisplay | null =>
  s.userProfile.display || null

export const selectAccess = (s: ReduxState): AccessState => s.access || {}

const selectPipelineIdFromOrder = (
  _: ReduxState,
  order: EditOrderEntity
): EditOrderEntity['pipelineId'] => order.pipelineId

const selectCustomerIdFromOrder = (
  _: ReduxState,
  order: EditOrderEntity
): EditOrderEntity['customerId'] => order.customerId

const selectIsNewCustomerFromOrder = (
  _: ReduxState,
  order: EditOrderEntity
): boolean => !!order.newCustomer && order.newCustomer.create

export const selectCustomer = createSelector(
  selectCustomers,
  (_: ReduxState, id: number | null) => id,
  (customers, customerId): CustomerEntity | null =>
    !!customerId ? customers.find((c) => c.id === customerId) || null : null
)

export const selectProjectsByCustomer = createSelector(
  selectProjects,
  (_: ReduxState, customerId: number | null) => customerId,
  (projects, customerId): ReadonlyArray<ProjectEntity> =>
    projects.filter((p) => p.customerId === customerId && p.status === 'ACTIVE')
)

export const selectCustomerFromOrder = createSelector(
  selectCustomers,
  selectIsNewCustomerFromOrder,
  selectCustomerIdFromOrder,
  (customers, isNew, customerId) =>
    !isNew ? customers.find((c) => c.id === customerId) || null : null
)

export const selectPipelineDealFromOrder = createSelector(
  selectPipelineDeals,
  selectPipelineIdFromOrder,
  (pipelineDeals, pipelineDealId) =>
    !pipelineDealId
      ? pipelineDeals.find((d) => d.id === pipelineDealId) || null
      : null
)

const selectCustomerIdFromProps = (
  _: ReduxState,
  props: Record<string, number | null>
): number | null => props.customerId

const selectProjectIdFromProps = (
  _: ReduxState,
  props: Record<string, number | null>
): number | null => props.projectId

export const selectPlatformsFromProps = createSelector(
  selectPlatforms,
  selectCustomerIdFromProps,
  selectProjectIdFromProps,
  (platforms, customerId, projectId): PlatformEntity[] => {
    return (platforms || []).filter((p) => {
      const cMatch = p.customerId === customerId
      const pMatch = projectId !== null ? p.projectId === projectId : true
      return cMatch && pMatch
    })
  }
)

export const selectKeyInFlight = createSelector(
  selectWdqlFlights,
  (_: ReduxState, flightKey: string) => flightKey,
  (flights, flightKey): boolean =>
    flights.findIndex((f) => f.key === flightKey) > -1
)

export const selectUploadedFilesByIds = createSelector(
  selectUploadedFiles,
  (_: ReduxState, fileIds: ReadonlyArray<number>) => fileIds,
  (files, fileIds): ReadonlyArray<UploadedFileEntity> =>
    files.filter((f) => fileIds.findIndex((fid) => f.id === fid) > -1)
)

export const selectUploadedFilesByIdString = createSelector(
  selectUploadedFiles,
  (_: ReduxState, idString: string): number[] =>
    idString.trim() === ''
      ? []
      : idString.split('|').map((i: string) => parseInt(i)),
  (files, fileIds): ReadonlyArray<UploadedFileEntity> =>
    files.filter((f) => fileIds.findIndex((fid) => f.id === fid) > -1)
)

export const selectUserById = createSelector(
  selectUsers,
  (_: ReduxState, userId: number | null) => userId,
  (users, userId): UserEntity | null =>
    userId ? users.find((o) => o.id === userId) || null : null
)

export const selectCampaignById = createSelector(
  selectCampaigns,
  (_: ReduxState, campaignId: number | null) => campaignId,
  (campaigns, campaignId): CampaignEntity | null =>
    campaignId ? campaigns.find((o) => o.id === campaignId) || null : null
)

export const selectCustomerById = createSelector(
  selectCustomers,
  (_: ReduxState, customerId: number | null) => customerId,
  (customers, customerId): CustomerEntity | null =>
    customerId ? customers.find((o) => o.id === customerId) || null : null
)

export const selectCustomersByIds = createSelector(
  selectCustomers,
  (_: ReduxState, customerIds: number[] | null) => customerIds,
  (customers, customerIds): CustomerEntity[] =>
    customerIds ? customers.filter((o) => customerIds.includes(o.id)) : []
)

export const selectOrderById = createSelector(
  selectOrders,
  (_: ReduxState, orderId: number | null) => orderId,
  (orders, orderId): OrderEntity | null =>
    orderId ? orders.find((o) => o.id === orderId) || null : null
)

export const selectOrderDraftById = createSelector(
  selectActiveOrderDrafts,
  (_: ReduxState, draftId: number | null) => draftId,
  (drafts, draftId): OrderDraftEntity | null =>
    draftId ? drafts.find((o) => o.id === draftId) || null : null
)

export const selectPipelineDealById = createSelector(
  selectPipelineDeals,
  (_: ReduxState, dealId: number | null) => dealId,
  (deals, dealId): PipelineDealsDeal | null =>
    dealId ? deals.find((o) => o.id === dealId) || null : null
)

export const selectPipelineContactById = createSelector(
  selectPipelineDealsPeople,
  (_: ReduxState, contactId: number | null) => contactId,
  (people, contactId): PipelineDealsContact | null =>
    contactId ? people.find((o) => o.id === contactId) || null : null
)

export const selectRoleAccessById = createSelector(
  selectRoles,
  (_: ReduxState, roleId: number | null) => roleId,
  (roles, roleId): AccessState => {
    let dbAccess: AccessState = {}
    if (roles && roleId) {
      const role = roles.find((r) => r.id === roleId)
      if (role && role.rules) {
        dbAccess = role.rules
      }
    }
    return dbAccess
  }
)

export const selectProjectById = createSelector(
  selectProjects,
  (_: ReduxState, projectId: number | null) => projectId,
  (projects, projectId): ProjectEntity | null =>
    projectId ? projects.find((o) => o.id === projectId) || null : null
)

export const selectPlatformById = createSelector(
  selectPlatforms,
  (_: ReduxState, platformId: number | null) => platformId,
  (platforms, platformId): PlatformEntity | null =>
    platformId ? platforms.find((o) => o.id === platformId) || null : null
)

interface MpbItemProps {
  id: number | null
  init?: boolean
}

export const selectMasterPriceBookItemById = createSelector(
  selectMasterPricebook,
  (_: ReduxState, props: MpbItemProps) => props.id,
  (_: ReduxState, props: MpbItemProps) => props.init || false,
  (pricebook, itemId, init): MasterPriceBookItemEntity | null => {
    const item = itemId
      ? pricebook.items.find((i) => i.id === itemId) || null
      : null
    return item === null && init ? getEmptyMasterPriceBookItem() : item
  }
)

interface SelectCustomerFilterProps {
  include?: number[] | number
  exclude?: number[] | number
  inactive?: boolean
}

const selectCustomersInclude = (
  _: ReduxState,
  p: SelectCustomerFilterProps
): number[] => (typeof p.include === 'number' ? [p.include] : p.include ?? [])

const selectCustomersExclude = (
  _: ReduxState,
  p: SelectCustomerFilterProps
): number[] => (typeof p.exclude === 'number' ? [p.exclude] : p.exclude ?? [])

const selectCustomersInactive = (
  _: ReduxState,
  p: SelectCustomerFilterProps
): boolean => p.inactive === true

export const selectCustomersFromProps = createSelector(
  selectCustomers,
  selectCustomersInclude,
  selectCustomersExclude,
  selectCustomersInactive,
  (customers, include, exclude, inactive): CustomerEntity[] => {
    return (customers || []).filter((c) => {
      if (include.length === 0 || include.findIndex((i) => i === c.id) > -1) {
        if (exclude.length > 0 && exclude.findIndex((i) => i === c.id) > -1) {
          return false
        }
        return !(!inactive && c.status === 'INACTIVE')
      }
      return false
    })
  }
)

export const selectCustomerParentPdIds = createSelector(
  selectCustomers,
  selectCustomer,
  (customers, customer): number[] => {
    const pdParentIds: number[] = []
    let parentId: number = customer && customer.parentId ? customer.parentId : 0
    while (parentId > 0) {
      const pIdCheck = parentId
      const parent = customers.find((c) => c.id === pIdCheck) || null
      parentId = 0
      if (parent) {
        if (parent.api?.pd) {
          pdParentIds.push(parent.api.pd)
        }
        parentId = parent && parent.parentId ? parent.parentId : 0
      }
    }
    return pdParentIds
  }
)

export const selectCustomerParentIds = createSelector(
  selectCustomers,
  selectCustomerById,
  (customers, customer): number[] => {
    const ids: number[] = []
    let parentId: number | null =
      customer && customer.parentId ? customer.parentId : 0
    while (!!parentId) {
      const parent = customers.find((c) => c.id === parentId) || null
      if (parent) {
        ids.push(parent.id)
        parentId = parent.parentId ?? null
      }
    }
    return ids
  }
)

const selectPipelineContactsIds = (
  _: ReduxState,
  p: number | number[]
): number[] => (typeof p === 'number' ? [p] : p ?? [])

export const selectPipelineContactsFiltered = createSelector(
  selectPipelineDealsPeople,
  selectPipelineContactsIds,
  (contacts, ids): PipelineDealsContact[] => {
    return ids.length > 0
      ? contacts.filter((c) => ids.findIndex((p) => p === c.companyId) > -1)
      : []
  }
)

// Credit Ledger Buckets

export const selectCreditLedgerBuckets = createSelector(
  (s: ReduxState) => s.entities.creditLedgerBuckets,
  (clbs): CreditLedgerBucketEntity[] => clbs || []
)

export const selectCreditLedgerBucketById = createSelector(
  selectCreditLedgerBuckets,
  (_: ReduxState, clbId: number | null) => clbId,
  (clbs, clbId): CreditLedgerBucketEntity | null =>
    clbId ? clbs.find((o) => o.id === clbId) || null : null
)

export const selectCreditLedgerBucketsByCustomerId = createSelector(
  selectCreditLedgerBuckets,
  (_: ReduxState, customerId: number | null) => customerId,
  (buckets, customerId): ReadonlyArray<CreditLedgerBucketEntity> =>
    buckets.filter((p) => p.customerId === customerId)
)

// const { customerId, selectedProject } = props
// return {
//   platforms: (s.entities.platforms || []).filter((p) => {
//     const cMatch = p.customerId === customerId
//     const pMatch =
//       selectedProject !== null ? p.projectId === selectedProject : true
//     return cMatch && pMatch
//   }),
// }

// interface PCard {
//   name:string
//   type:string
// }
//
// interface PState {
//   pokemon: {
//     [k:number]: PCard
//   }
// }
//
// interface SProps {
//   name?:string,
//   type?:string,
//   extra?:string
// }
//
// const initialState : PState = {
//   pokemon: {
//     1: { name: "Bulbasaur", type: "Grass" },
//     4: { name: "Charmander", type: "Fire" },
//     7: { name: "Squirtle", type: "Water" }
//   }
// };
//
//
// // // A helper function to create the parameter selectors
//
//
// const getPokemon = (state:PState) => state.pokemon;
// const getNameParam = createParameterSelector((params:SProps) => params.name);
// const getTypeParam = createParameterSelector((params:SProps) => params.type);
// // const getNameParam = (_:PState, params:SProps) => (params:SProps) => params.name
// // const getTypeParam = (_:PState, params:SProps) => (params:SProps) => params.type
// //const getExtraParam = (_:PState, params:SProps) => (params:SProps) => params.extra
// const searchPokemon = createSelector(
//   getPokemon,
//   getTypeParam,
//   getNameParam,
//   //getExtraParam,
//   (pokemon, type, name) => {
//     // Filter our Pokemon
//     return Object.values(pokemon)
//       .filter((p:PCard) => p.type === type)
//       .filter((p:PCard) => !!name ? p.name.includes(name) : false);
//   }
// );
//
// const x = useSelector((s:PState)=>searchPokemon(s,{name:'Squi',extra:'434'}))

// import {createCachedSelector} from 're-reselect';
//
// // Normal reselect routine: declare "inputSelectors" and "resultFunc"
// const getUsers = state => state.users;
// const getLibraryId = (state, libraryName) => state.libraries[libraryName].id;
//
// const getUsersByLibrary = createCachedSelector(
//   // inputSelectors
//   getUsers,
//   getLibraryId,
//
//   // resultFunc
//   (users, libraryId) => expensiveComputation(users, libraryId),
// )(
//   // re-reselect keySelector (receives selectors' arguments)
//   // Use "libraryName" as cacheKey
//   (_state_, libraryName) => libraryName
// );
//
// // Cached selectors behave like normal selectors:
// // 2 reselect selectors are created, called and cached
// const reactUsers = getUsersByLibrary(state, 'react');
// const vueUsers = getUsersByLibrary(state, 'vue');
//
// // This 3rd call hits the cache
// const reactUsersAgain = getUsersByLibrary(state, 'react');
// // reactUsers === reactUsersAgain
// // "expensiveComputation" called twice in total
