import { action } from 'typesafe-actions'
import {
  WDQL_ADD_CACHE,
  WDQL_ADD_PATCH_CONFLICT,
  WDQL_RESOLVE_PATCH_CONFLICT,
  WDQL_UPDATE_FLIGHT_STATUS,
} from '../constants/action-types'
import { WdqlFlightStatus, WdqlPatchConflict } from './types'

export const addWdqlCacheKey = (payload: { key: string; value: number }) =>
  action(WDQL_ADD_CACHE, payload)
export const addPatchConflict = (payload: WdqlPatchConflict) =>
  action(WDQL_ADD_PATCH_CONFLICT, payload)
export const resolvePatchConflict = (payload: string) =>
  action(WDQL_RESOLVE_PATCH_CONFLICT, payload)
export const updateFlightStatus = (payload: WdqlFlightStatus) =>
  action(WDQL_UPDATE_FLIGHT_STATUS, payload)
