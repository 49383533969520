import { ActionType } from 'typesafe-actions'
import produce from 'immer'
import dotProp from 'dot-prop'
import * as actions from '../actions/customers'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import { CustomerEntity } from '../types/entity-types'
import { CustomerApiMapUpdatePayload } from '../actions/customers'

const CustomersReducer = (
  state: CustomerEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_CUSTOMER:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_CUSTOMERS:
      return wdqlMergeSet(state, action.payload)
    case actions.UPDATE_CUSTOMER_API_MAP:
      return _patchCustomerApiMap(state, action.payload)
    default:
      return state
  }
}

export default CustomersReducer

const _patchCustomerApiMap = (
  s: CustomerEntity[],
  a: CustomerApiMapUpdatePayload
) => {
  const { customerId, code, value } = a
  return produce(s, (newSet: CustomerEntity[]) => {
    const ind = newSet.findIndex((c) => c.id === customerId)
    if (ind > -1) {
      dotProp.set(newSet[ind], 'api.' + code, !!value ? value : null)
    }
    return newSet
  })
}
