import { action } from 'typesafe-actions'
import { RoleEntity } from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_ROLES = 'WDQL_MERGE_ROLES'
export const WDQL_MERGE_ROLE = 'WDQL_MERGE_ROLE'
export const UPDATE_ROLE = 'UPDATE_ROLE'

// Action Definitions
export const updateRole = (payload: RoleEntity) => action(UPDATE_ROLE, payload)

// WDQL actions
export const wdqlMergeRoles = (payload: RoleEntity[]) =>
  action(WDQL_MERGE_ROLES, payload)
export const wdqlMergeRole = (payload: RoleEntity) =>
  action(WDQL_MERGE_ROLE, payload)
