// order Actions
export const WDQL_ADD_CACHE = 'WDQL_ADD_CACHE'
export const WDQL_ADD_PATCH_CONFLICT = 'WDQL_ADD_PATCH_CONFLICT'
export const WDQL_RESOLVE_PATCH_CONFLICT = 'WDQL_RESOLVE_PATCH_CONFLICT'
export const WDQL_UPDATE_FLIGHT_STATUS = 'WDQL_UPDATE_FLIGHT_STATUS'

// userProfile Actions
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE'

// session Actions
export const UPDATE_SESSION_AUTH = 'UPDATE_SESSION_AUTH'
export const CLEAR_SESSION_AUTH = 'CLEAR_SESSION_AUTH'

// access Actions
export const UPDATE_USER_ACCESS = 'UPDATE_USER_ACCESS'
export const CLEAR_USER_ACCESS = 'CLEAR_USER_ACCESS'

// interface Actions
export const REPLACE_FAVORITES = 'REPLACE_FAVORITES'
export const CLEAR_INTERFACE = 'CLEAR_INTERFACE'

// pipeline Actions
export const ADD_PD_DEAL = 'ADD_PD_DEAL'
export const ADD_PD_CONTACTS = 'ADD_PD_CONTACTS'

// helpscout Actions
export const ADD_PD_CONVERSATIONS = 'ADD_PD_CONVERSATIONS'
