import { action } from 'typesafe-actions'
import { CustomerEntity } from '../types/entity-types'

export interface CustomerApiMapUpdatePayload {
  customerId: number
  code: string
  value: string | null
}

// Action Keys
export const WDQL_MERGE_CUSTOMERS = 'WDQL_MERGE_CUSTOMERS'
export const WDQL_MERGE_CUSTOMER = 'WDQL_MERGE_CUSTOMER'
export const UPDATE_CUSTOMER_API_MAP = 'UPDATE_CUSTOMER_API_MAP'

// Action Definitions
export const wdqlMergeCustomers = (payload: CustomerEntity[]) =>
  action(WDQL_MERGE_CUSTOMERS, payload)

export const wdqlMergeCustomer = (payload: CustomerEntity) =>
  action(WDQL_MERGE_CUSTOMER, payload)

export const patchCustomerApiMap = (payload: CustomerApiMapUpdatePayload) =>
  action(UPDATE_CUSTOMER_API_MAP, payload)
