import { action } from 'typesafe-actions'
import {
  MasterPriceBookCategoryEntity,
  MasterPriceBookItemEntity,
} from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_MPB_CATEGORIES = 'WDQL_MERGE_MPB_CATEGORIES'
export const WDQL_MERGE_MPB_CATEGORY = 'WDQL_MERGE_MPB_CATEGORY'
export const WDQL_MERGE_MPB_ITEMS = 'WDQL_MERGE_MPB_ITEMS'
export const WDQL_MERGE_MPB_ITEM = 'WDQL_MERGE_MPB_ITEM'

// Action Definitions
// WDQL actions
export const wdqlMergeMpbCategories = (
  payload: MasterPriceBookCategoryEntity[]
) => action(WDQL_MERGE_MPB_CATEGORIES, payload)
export const wdqlMergeMpbCategory = (payload: MasterPriceBookCategoryEntity) =>
  action(WDQL_MERGE_MPB_CATEGORY, payload)
export const wdqlMergeMpbItems = (payload: MasterPriceBookItemEntity[]) =>
  action(WDQL_MERGE_MPB_ITEMS, payload)
export const wdqlMergeMpbItem = (payload: MasterPriceBookItemEntity) =>
  action(WDQL_MERGE_MPB_ITEM, payload)
