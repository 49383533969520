export enum GlobalActionType {
  TOGGLE_SCREEN_LOADING = 'Toggle Loading Screen',
  SET_SCREEN_LOADING = 'Set Screen Loading',
  TOGGLE_OPEN_EDITOR = 'Toggle Open Editor',
  ALL_OPEN_EDITORS_CLOSED = 'Set All Open Editors to Closed',
}

export type GlobalStateActions =
  | { type: GlobalActionType.TOGGLE_SCREEN_LOADING }
  | {
      type: GlobalActionType.SET_SCREEN_LOADING
      status?: boolean
      caption?: string
    }
  | { type: GlobalActionType.TOGGLE_OPEN_EDITOR; code: string; isOpen: boolean }
  | { type: GlobalActionType.ALL_OPEN_EDITORS_CLOSED }

export interface GlobalStateInterface {
  loading: { status: boolean; caption: string }
  openEditors: { [k: string]: boolean }
}

export const initialGlobalState: GlobalStateInterface = {
  loading: { status: false, caption: 'Loading...' },
  openEditors: {},
}

export const globalReducer = (
  state: GlobalStateInterface,
  action: GlobalStateActions
): GlobalStateInterface => {
  const a = action.type ?? 'UNDEFINED'
  switch (action.type) {
    case GlobalActionType.TOGGLE_SCREEN_LOADING:
      return {
        ...state,
        loading: { ...state.loading, status: !state.loading.status },
      }
    case GlobalActionType.SET_SCREEN_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          status: action.status ?? !state.loading.status,
          caption: action.status
            ? action.caption ?? initialGlobalState.loading.caption
            : state.loading.caption,
        },
      }
    case GlobalActionType.ALL_OPEN_EDITORS_CLOSED:
      return {
        ...state,
        openEditors: {},
      }
    case GlobalActionType.TOGGLE_OPEN_EDITOR:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [action.code]: _, ...newEditors } = { ...state.openEditors }
      if (action.isOpen) {
        newEditors[action.code] = true
      }
      return {
        ...state,
        openEditors: newEditors,
      }

    default:
      throw new Error('Call to undefined global state reducer action... ' + a)
  }
}
