import axios from 'axios'
import { updateUserProfile } from '../actions/userProfile'
import { updateSessionData } from '../actions/session'
import { updateAccessData } from '../actions/access'
import { replaceFavorites } from '../actions/interface'
import {
  UserProfile,
  SessionAuthState,
  AccessState,
  FavoriteEntry,
} from '../actions/types'
import { UserEntity } from '../types/entity-types'
import { getStoreInstance } from '../store'
import { updateAxiosDefaults } from './remote/axios'
import debug from './debug'

interface GoogleAuthData {
  tokenId: string
  googleId: string
  email: string
}

export interface AuthStateResponse {
  success: boolean
  user: UserProfile
  userObject: UserEntity
  session: SessionAuthState
  google: GoogleAuthData
  access: AccessState
  favorites: FavoriteEntry[]
  errors?: string[]
}

// Perform all the storage updates from an auth response

const updateAuthState = (authData: AuthStateResponse) => {
  const store = getStoreInstance() || null
  if (!authData || !authData.google) {
    return false
  }
  //console.log('UPDATE AUTH STATE', authData)

  localStorage.setItem('tokenId', authData.google.tokenId)
  localStorage.setItem('googleId', authData.google.googleId)
  localStorage.setItem('email', authData.google.email)

  // Set Local Storage
  if (authData.session.id) {
    localStorage.setItem('sessionId', authData.session.id) // was sessionStorage
  }
  if (authData.session.name) {
    localStorage.setItem('sessionName', authData.session.name) // was sessionStorage
  }
  //console.log('set Session', localStorage);
  updateAxiosDefaults()

  // User Local Storage to Update STORE by dispatching reducers
  if (store) {
    if (authData.user) {
      store.dispatch(updateUserProfile(authData.user))
    }
    if (authData.session) {
      store.dispatch(updateSessionData(authData.session))
    }
    if (authData.access) {
      store.dispatch(updateAccessData(authData.access))
    }
    if (authData.favorites) {
      store.dispatch(replaceFavorites(authData.favorites))
    }
  }
  const update = {
    authData: authData,
    axios: axios.defaults,
    storage: {
      local: localStorage,
      //session: sessionStorage,
    },
    state: store ? store.getState() : null,
  }
  debug.tron(update, 'AUTH_STATE_UPDATED')
}

export default updateAuthState
