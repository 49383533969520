import { clearUserProfile } from '../actions/userProfile'
import { clearSessionData } from '../actions/session'
import { clearAccessData } from '../actions/access'
import { clearInterface } from '../actions/interface'
import { getStoreInstance } from '../store'
import { updateAxiosDefaults } from './remote/axios'
import redirector from './redirector'
import debug from './debug'
import { navigate } from './'

export const validAuth = (response: { headers: any }) => {
  const authVerified = response.headers['auth-verified'] === 'true'
  if (!authVerified) {
    redirector.current()
    logOut()
    return false
  }
  return true
}

export const logOut = (e: Event | null = null) => {
  if (e) {
    e.preventDefault()
  }
  debug.log('LOGOUT - Clear Data Now!!!')
  // return

  localStorage.removeItem('email')
  localStorage.removeItem('tokenId')
  localStorage.removeItem('googleId')
  localStorage.removeItem('sessionId') // was sessionStorage
  localStorage.removeItem('sessionName') // was sessionStorage
  localStorage.removeItem('ghostId')
  updateAxiosDefaults()
  const store = getStoreInstance()
  if (store) {
    store.dispatch(clearUserProfile())
    store.dispatch(clearSessionData())
    store.dispatch(clearAccessData())
    store.dispatch(clearInterface())
  }
  navigate('/login')
}

export const isLoggedIn = () => {
  const googleId = localStorage.getItem('googleId')
  return !!googleId
}

export function onLoginScreen(): boolean {
  const path = String(window.location.pathname).toLowerCase()
  return path === '/login' || path === '/activate'
}
