import axios from 'axios'

export const updateAxiosDefaults = () => {
  let sessionId = null
  let sessionName = 'PHPSESSID'
  if (localStorage.getItem('sessionId')) {
    // was sessionStorage
    sessionId = localStorage.getItem('sessionId') // was sessionStorage
    sessionName = localStorage.getItem('sessionName') || sessionName // was sessionStorage
  }

  axios.defaults.headers.common['Authorization'] = null
  if (localStorage.getItem('tokenId')) {
    axios.defaults.headers.common['Authorization'] =
      localStorage.getItem('tokenId')
  }
  //axios.defaults.headers.common['phpsession'] = 'FAKESESSION'
  axios.defaults.params = {}

  if (sessionId) {
    axios.defaults.headers.common['phpsession'] = sessionName + '::' + sessionId
    //axios.defaults.params[sessionName] = sessionId
  }

  //console.log('SESSION', sessionStorage);
  //console.log('LOCAL STORAGE', localStorage);
  //console.log('AXIOS DEFAULTS', axios.defaults);
}
