import axios, { AxiosResponse } from 'axios'
import debug from '../debug'
import { getStoreInstance } from '../../store'
import { onLoginScreen, validAuth } from '../authenticate'

//import { AM_API_DOMAIN } from "../../environment";

//const AM_API_DOMAIN = process.env.REACT_APP_ACCOUNTMANAGER_API_DOMAIN

interface PostOptions {
  request?: Record<string, any>
}

export async function get(url: string): Promise<AxiosResponse> {
  const AM_API_DOMAIN = process.env.REACT_APP_ACCOUNTMANAGER_API_DOMAIN
  const FullUrl = AM_API_DOMAIN + url
  const response = await axios.get(FullUrl).catch((e: Error) => {
    throw new Error('GET FAILED :' + e)
  })
  const store = getStoreInstance() || null
  const request = {
    url: FullUrl,
    axiosDefaults: axios.defaults,
    storage: {
      local: localStorage,
      //session: sessionStorage,
    },
    state: store ? store.getState() : null,
  }
  debug.tron({ request, response }, 'AXIOS', 'GET: ' + FullUrl)
  //console.log('AXIOS GET', request)

  if (!validAuth(response)) {
    if (!onLoginScreen()) {
      throw new Error('GET: Authentication Check Failed - ' + FullUrl)
    }
  }
  return response
}

export async function post(
  url: string,
  payload: Record<string, any>,
  options: PostOptions = {}
): Promise<AxiosResponse> {
  const AM_API_DOMAIN = process.env.REACT_APP_ACCOUNTMANAGER_API_DOMAIN
  const FullUrl = AM_API_DOMAIN + url
  const data = Object.assign(
    {},
    { payload: payload },
    options.request ? options.request : {}
  )
  const response = await axios.post(FullUrl, data).catch((e: Error) => {
    throw new Error('POST FAILED :' + e)
  })
  const store = getStoreInstance() || null
  const request = {
    url: FullUrl,
    data: data,
    axiosDefaults: axios.defaults,
    storage: {
      local: localStorage,
      // session: sessionStorage,
    },
    state: store ? store.getState() : null,
  }
  debug.tron({ request, response }, 'AXIOS', 'POST: ' + FullUrl)
  //console.log('AXIOS POST', request);

  if (!validAuth(response)) {
    if (!onLoginScreen()) {
      throw new Error(
        'POST - Authentication Check Failed - ' +
          FullUrl +
          ' from ' +
          window.location.pathname
      )
    }
  }
  return response
}

// import type { Store } from 'redux'
// import type { ReduxState } from '../types'
// import getFileExtension from './getFileExtension'
//
// // TO BE UPDATED. HERE FOR REFERENCE
//
// const forceDelay = !!process.env.REACT_NATIVE_DELAY_GRAPHQL
// const delay = (ms) => new Promise((_) => setTimeout(_, ms))
// const delayedFetch = (uri, options) => {
//   const ms = Number(process.env.REACT_NATIVE_DELAY_GRAPHQL)
//   return delay(ms).then(() => fetch(uri, options))
// }
//
// const fetchImpl = forceDelay ? delayedFetch : fetch
//
// let storeInstance: Store<ReduxState, *>
//
// export function setStoreInstance(store: Store<ReduxState, *>) {
//   storeInstance = store
// }
//
// export function getStoreInstance() {
//   return storeInstance
// }
//
// export function get(url: string): Promise<Object> {
//   return fetchImpl(url, {
//     method: 'GET',
//     headers: getHeaders(),
//   }).then(handleUnauthorized)
// }
//
// export function post(url: string, body: any): Promise<Object> {
//   return fetchImpl(url, {
//     method: 'POST',
//     headers: getHeaders(),
//     body: JSON.stringify(body),
//   }).then(handleUnauthorized)
// }
//
// export function put(url: string, body: any): Promise<Object> {
//   return fetchImpl(url, {
//     method: 'PUT',
//     headers: getHeaders(),
//     body: JSON.stringify(body),
//   }).then(handleUnauthorized)
// }
//
// export function patch(url: string, body: any): Promise<Object> {
//   return fetchImpl(url, {
//     method: 'PATCH',
//     headers: getHeaders(),
//     body: JSON.stringify(body),
//   }).then(handleUnauthorized)
// }
//
// export function del(url: string): Promise<Object> {
//   return fetchImpl(url, {
//     method: 'DELETE',
//     headers: getHeaders(),
//   }).then(handleUnauthorized)
// }
//
// export function uploadImage(
//   url: string,
//   fieldName: string,
//   fileUri: string
// ): Promise<string> {
//   const extension = getFileExtension(fileUri) || 'jpg'
//
//   const form: Object = new FormData()
//   form.append(fieldName, {
//     uri: fileUri,
//     type: 'image',
//     name: 'placeholder-do-not-use.jpg',
//     extension,
//   })
//   form.append('extension', extension)
//
//   return fetchImpl(url, { method: 'POST', body: form, headers: getHeaders() })
//     .then(handleUnauthorized)
//     .then((r) => r.json())
//     .then((r) => r.url)
// }
//
// export function uploadMediaAsset(
//   url: string,
//   fieldName: string,
//   fileUri: string
// ): Promise<string> {
//   const form: Object = new FormData()
//   form.append(fieldName, {
//     uri: fileUri,
//     type: 'image',
//     name: 'placeholder-do-not-use.jpg',
//   })
//
//   return fetchImpl(url, {
//     method: 'POST',
//     body: form,
//     headers: getHeaders(),
//   })
//     .then(handleUnauthorized)
//     .then((r) => r.json())
//     .then((r) => r.url)
// }
//
// export function getAuthToken() {
//   return storeInstance.getState().auth.token
// }
//
// function getHeaders(): Object {
//   const headers = {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//   }
//
//   const authToken = storeInstance.getState().auth.token
//   if (authToken) {
//     return { ...headers, Authorization: `Bearer ${authToken}` }
//   }
//
//   return headers
// }
//
// function handleUnauthorized(response) {
//   if (response.status === 401) {
//     storeInstance.dispatch({ type: 'UNAUTHORIZED' })
//   }
//   return response
// }
