import { ConflictHandlerObject } from './types'
import { autoFormatType } from './utilities'

const OrderConflictHandler: ConflictHandlerObject = {
  header: (o: any) => {
    return `Sales Order #${o.object.id} : ${o.object.name}`
  },
  label: (k: string) => {
    switch (k) {
      case '/accounting/approved':
        return 'Accounting Approval'
      case '/accountingMessage':
        return 'Accounting Message'
    }
    return k
  },
  value: (k: string, v: any) => {
    return autoFormatType(v)
  },
}

export default OrderConflictHandler
