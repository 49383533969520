import { MasterPriceBookItemEntity } from '../types/entity-types'

export const getEmptyMasterPriceBookItem = (): MasterPriceBookItemEntity => {
  return {
    id: 0,
    categoryId: 0,
    name: '',
    description: '',
    sku: '#000000',
    unitInput: 'TIME',
    unitLabel: {
      single: { full: 'Hour', abbr: 'hr' },
      multiple: { full: 'Hours', abbr: 'hrs' },
    },
    msrp: 0,
    minimumPrice: 0,
    allow: { oneTime: true, recurring: true, renewal: false, range: true },
    defaults: {
      mode: 'ONETIME',
      oneTime: 'FIXED',
      recurring: { period: 'MONTH', term: 12 },
    },
    cog: { perUnit: { type: 'FIXED', amount: 0 } },
    discount: null,
    orderNumber: 0,
    status: 'ACTIVE',
  }
}
