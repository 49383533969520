import { ActionType } from 'typesafe-actions'
import * as actions from '../actions/stars'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import { StarEntity } from '../types/entity-types'

const StarReducer = (
  state: StarEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_STAR:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_STARS:
      return wdqlMergeSet(state, action.payload)
    default:
      return state
  }
}

export default StarReducer
