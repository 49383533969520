import { action } from 'typesafe-actions'
import { CreditLedgerBucketEntity } from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_CREDIT_LEDGER_BUCKETS = 'CREDIT_LEDGER_BUCKETS'
export const WDQL_MERGE_CREDIT_LEDGER_BUCKET = 'CREDIT_LEDGER_BUCKET'

// Action Definitions
// WDQL actions
export const wdqlMergeCreditLedgerBuckets = (
  payload: CreditLedgerBucketEntity[]
) => action(WDQL_MERGE_CREDIT_LEDGER_BUCKETS, payload)
export const wdqlMergeCreditLedgerBucket = (
  payload: CreditLedgerBucketEntity
) => action(WDQL_MERGE_CREDIT_LEDGER_BUCKET, payload)
