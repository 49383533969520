import { ConflictHandlerObject } from './types'
import OrderConflictHandler from './OrderConflictHandler'
import { autoFormatType } from './utilities'

interface HandlerMap {
  [key: string]: ConflictHandlerObject
}

// List all imported Conflict Handlers...
const handlers: HandlerMap = {
  order: OrderConflictHandler,
}

const DefaultConflictHandler: ConflictHandlerObject = {
  header: (o: any) => {
    const prefix = String(o.key).ucFirst()
    const id = o.object && o.object.id ? ' #' + String(o.object.id) : ''
    const suffix =
      o.object && o.object.name
        ? ' : ' + o.object.name
        : o.object && o.object.label
        ? ' : ' + o.object.label
        : ''
    return `${prefix}${id}${suffix}`
  },
  label: (k: string) => {
    return k.split('/').join(' ').ucWords()
  },
  value: (k: string, v: any) => {
    return autoFormatType(v)
  },
}

const getConflictHandler = (entityKey: string): ConflictHandlerObject => {
  if (handlers.hasOwnProperty(entityKey)) {
    return handlers[entityKey]
  }
  return DefaultConflictHandler
}

export default getConflictHandler
