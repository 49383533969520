import { action } from 'typesafe-actions'
import { UploadedFileEntity } from '../types/entity-types'

// Action Keys
export const WDQL_MERGE_UPLOADED_FILES = 'WDQL_MERGE_UPLOADED_FILES'
export const WDQL_MERGE_UPLOADED_FILE = 'WDQL_MERGE_UPLOADED_FILE'
export const MERGE_UPLOADED_FILES = 'MERGE_UPLOADED_FILES'
export const MERGE_UPLOADED_FILE = 'MERGE_UPLOADED_FILE'
export const REMOVE_UPLOADED_FILE = 'REMOVE_UPLOADED_FILE'
export const REMOVE_UPLOADED_FILES = 'REMOVE_UPLOADED_FILES'

// Action Definitions
// WDQL actions
export const wdqlMergeUploadedFiles = (payload: UploadedFileEntity[]) =>
  action(WDQL_MERGE_UPLOADED_FILES, payload)
export const wdqlMergeUploadedFile = (payload: UploadedFileEntity) =>
  action(WDQL_MERGE_UPLOADED_FILE, payload)
export const mergeUploadedFiles = (payload: UploadedFileEntity[]) =>
  action(MERGE_UPLOADED_FILES, payload)
export const mergeUploadedFile = (payload: UploadedFileEntity) =>
  action(MERGE_UPLOADED_FILE, payload)
export const removeUploadedFile = (payload: number | number[]) =>
  action(REMOVE_UPLOADED_FILE, payload)
export const removeUploadedFiles = (payload: number | number[]) =>
  action(REMOVE_UPLOADED_FILES, payload)
