import { ActionType } from 'typesafe-actions'
import produce from 'immer'
import dotProp from 'dot-prop'
import * as actions from '../actions/users'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import { UserEntity } from '../types/entity-types'
import { UserApiMapUpdatePayload } from '../actions/users'

const UserReducer = (
  state: UserEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_USERS:
      return wdqlMergeSet(state, action.payload, 'id', ['ccAccess'])
    case actions.WDQL_MERGE_USER:
      return wdqlMergeOne(state, action.payload, 'id', ['ccAccess'])
    case actions.UPDATE_USER:
      return wdqlMergeOne(state, action.payload, 'id', ['ccAccess'])
    case actions.UPDATE_USER_API_MAP:
      return _patchApiMap(state, action.payload)
    default:
      return state
  }
}

export default UserReducer

const _patchApiMap = (s: UserEntity[], a: UserApiMapUpdatePayload) => {
  const { userId, code, value } = a
  return produce(s, (newSet: UserEntity[]) => {
    const ind = newSet.findIndex((u) => u.id === userId)
    if (ind > -1) {
      dotProp.set(newSet[ind], 'api.' + code, !!value ? value : null)
    }
    return newSet
  })
}
