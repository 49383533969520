import produce from 'immer'
import { ActionType } from 'typesafe-actions'
import {
  WDQL_ADD_CACHE,
  WDQL_ADD_PATCH_CONFLICT,
  WDQL_RESOLVE_PATCH_CONFLICT,
  WDQL_UPDATE_FLIGHT_STATUS,
} from '../constants/action-types'
import * as actions from '../actions/wdql'
import { WdqlState } from '../actions/types'

const WdqlReducer = (
  state: WdqlState = {
    cache: {},
    conflicts: [],
    flights: [],
  },
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case WDQL_ADD_CACHE:
      return produce(state, (newSet: WdqlState) => {
        newSet.cache[action.payload.key] = action.payload.value
        return newSet
      })
    case WDQL_ADD_PATCH_CONFLICT:
      return produce(state, (newSet: WdqlState) => {
        const conflicts = newSet.conflicts
        conflicts.push(action.payload)
        return newSet
      })
    case WDQL_RESOLVE_PATCH_CONFLICT:
      return produce(state, (newSet: WdqlState) => {
        if (newSet.conflicts && newSet.conflicts.length > 0) {
          const conflictId = action.payload
          newSet.conflicts = state
            ? state.conflicts.filter((c) => c.id !== conflictId)
            : []
        }
        return newSet
      })
    case WDQL_UPDATE_FLIGHT_STATUS:
      return produce(state, (newSet: WdqlState) => {
        const flightIndex = newSet.flights.findIndex(
          (f) => f.key === action.payload.key
        )
        const inFlight = action.payload.status
        if ((flightIndex > -1 && inFlight) || (flightIndex < 0 && !inFlight)) {
          return newSet
        }
        if (flightIndex < 0 && inFlight) {
          newSet.flights.push(action.payload)
        }
        if (flightIndex > -1 && !inFlight) {
          newSet.flights.splice(flightIndex, 1)
        }
        return newSet
      })
    default:
      return state
  }
}

export default WdqlReducer
