export { default as navigate } from './navigate'

export function chunk<T>(arr: T[], size: number): Array<T[]> {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )
}

export function toDate(date: string | null = null): string {
  const d = !!date ? new Date(date) : new Date()
  return [
    d.getFullYear(),
    ('' + (d.getMonth() + 1)).padStart(2, '0'),
    ('' + d.getDate()).padStart(2, '0'),
  ].join('-')
}
