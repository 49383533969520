import { ActionType } from 'typesafe-actions'
import * as actions from '../actions/activityFeed'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import { ActivityFeedEntity } from '../types/entity-types'

const ActivityFeedReducer = (
  state: ActivityFeedEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_ACTIVITY_ITEM:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_ACTIVITY_FEED:
      return wdqlMergeSet(state, action.payload)
    default:
      return state
  }
}

export default ActivityFeedReducer
