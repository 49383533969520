import { action } from 'typesafe-actions'
import {
  CLEAR_SESSION_AUTH,
  UPDATE_SESSION_AUTH,
} from '../constants/action-types'
import { SessionAuthState } from './types'

export const updateSessionData = (payload: SessionAuthState) =>
  action(UPDATE_SESSION_AUTH, payload)
export const clearSessionData = () => action(CLEAR_SESSION_AUTH)

// export function getData() {
//     return function(dispatch) {
//         return fetch("https://jsonplaceholder.typicode.com/posts")
//             .then(response => response.json())
//             .then(json => {
//                 dispatch({ type: "DATA_LOADED", payload: json });
//             });
//     };
// }
