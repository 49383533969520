import { ActionType } from 'typesafe-actions'
import produce from 'immer'
import * as actions from '../actions/orders'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import {
  OrderDraftEntity,
  OrderEntity,
  OrderItemEntity,
} from '../types/entity-types'

const OrdersReducer = (
  state: OrderEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_ORDER:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_ORDERS:
      return wdqlMergeSet(state, action.payload)
    default:
      return state
  }
}

export default OrdersReducer

export const OrderItemsReducer = (
  state: OrderItemEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_ORDER_ITEM:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_ORDER_ITEMS:
      return wdqlMergeSet(state, action.payload)
    case actions.REMOVE_ORDER_ITEMS:
      return produce(state, (items) => {
        const ids: number[] = (
          Array.isArray(action.payload) ? action.payload : [action.payload]
        ).map((i: number | { id: number }) => {
          if (typeof i === 'number') {
            return i
          }
          return i.id
        })
        const newItems = items.filter(
          (item: OrderItemEntity) => ids.findIndex((i) => item.id === i) < 0
        )
        return newItems
      })
    default:
      return state
  }
}

export const OrderDraftReducer = (
  state: OrderDraftEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_ORDER_DRAFT:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_ORDER_DRAFTS:
      return wdqlMergeSet(state, action.payload)
    case actions.WDQL_REMOVE_ORDER_DRAFT:
      const id = action.payload
      return state.filter((d) => d.id !== id)
    default:
      return state
  }
}
