import { ActionType } from 'typesafe-actions'
import * as actions from '../actions/credit-ledger-buckets'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import { CreditLedgerBucketEntity } from '../types/entity-types'

const CreditLedgerBucketReducer = (
  state: CreditLedgerBucketEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_CREDIT_LEDGER_BUCKET:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_CREDIT_LEDGER_BUCKETS:
      return wdqlMergeSet(state, action.payload)
    default:
      return state
  }
}

export default CreditLedgerBucketReducer
