import stringify from 'json-stable-stringify'

export const autoFormatType = (v: any): string => {
  if (typeof v === 'string') {
    return v
  } // Already a string skip all other checks and just send it back
  if (typeof v === 'boolean') {
    return v ? 'True' : 'False'
  }
  if (typeof v === 'object') {
    return stringify(v)
  }
  return String(v)
}
