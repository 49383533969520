import { ActionType } from 'typesafe-actions'
import { CLEAR_INTERFACE, REPLACE_FAVORITES } from '../constants/action-types'
import * as actions from '../actions/interface'

const InterfaceReducer = (state = {}, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case REPLACE_FAVORITES:
      return Object.assign({}, state, { favorites: action.payload })
    case CLEAR_INTERFACE:
      return {
        favorites: [],
      }
    default:
      return state
  }
}

export default InterfaceReducer
