import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'react-app-polyfill/ie11' // For IE 11 support
import './polyfill'
import './reactotron-config'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './index.css'
import ReduxToastr from 'react-redux-toastr'
import { enableAllPlugins } from 'immer'
import App from './App'
import store from './store'
import * as serviceWorker from './serviceWorker'
import { updateAxiosDefaults } from './util/remote/axios'
import './util/extenders'
import ConflictHandler from './components/conflicts/ConflictHandler'

enableAllPlugins()
updateAxiosDefaults()
//console.log(localStorage,sessionStorage)

//console.log(process.env.NODE_ENV)
//console.log(process.env.REACT_APP_ACCOUNTMANAGER_API_DOMAIN)

// const noop = ()=>{}
// if (process.env.NODE_ENV !== 'development') {
//   console.log = noop
//   console.warn = noop
//   console.error = noop
// }

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ConflictHandler />
    <ReduxToastr
      timeOut={3000}
      newestOnTop={false}
      position="bottom-right"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick
    />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
