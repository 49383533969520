import { ActionType } from 'typesafe-actions'
import * as actions from '../actions/addresses'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import { AddressEntity } from '../types/entity-types'

const AddressReducer = (
  state: AddressEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_ADDRESS:
      return wdqlMergeOne(state, action.payload)
    case actions.WDQL_MERGE_ADDRESSES:
      return wdqlMergeSet(state, action.payload)
    default:
      return state
  }
}

export default AddressReducer
