import { ActionType } from 'typesafe-actions'
import * as actions from '../actions/roles'
import { wdqlMergeOne, wdqlMergeSet } from '../util/remote/wdql'
import { RoleEntity } from '../types/entity-types'

const RoleReducer = (
  state: RoleEntity[] = [],
  action: ActionType<typeof actions>
) => {
  switch (action.type) {
    case actions.WDQL_MERGE_ROLES:
      return wdqlMergeSet(state, action.payload, 'id', ['access', 'rules'])
    case actions.WDQL_MERGE_ROLE:
      return wdqlMergeOne(state, action.payload, 'id', ['access', 'rules'])
    case actions.UPDATE_ROLE:
      return wdqlMergeOne(state, action.payload, 'id', ['access', 'rules'])
    default:
      return state
  }
}

export default RoleReducer
